// Component used to edit an service. At start, it loads the service data from the server and shows it in the form.
// When the user clicks on the "Save" button, the data is sent to the server and the service is updated.

//* Libraries Imports
import { toast, ToastContainer } from "react-toastify";
import { useState, useEffect } from 'react';
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//* Components Imports
import { ServicesForm } from "./components/ServicesForm";

//* Utils, Types, Requests Imports
import { useService } from "./utils";
import { ApiService } from "../../types/services";
import Request from "../../utils/Request";
import { validateFormData } from "./editUtils";


export const EditService = () => {
  // this state is used to store the service data loaded from the server
  const { id } = useParams(); // get the id from the url
  const navigate = useNavigate(); // used to navigate to another page
  const [oldImages, setOldImages] = useState<string[]>([]); // set the old images to an empty array [
  const { service, loading } = useService(id || "");
  const role = useSelector((state: any) => state.user); // get the user role from the redux store
  if (role !== 'adm') navigate('/dashboard'); // if the user is not an admin, redirect to the home page

  // this function is used to send the service data to the server
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement); // get the form data
    oldImages.forEach((image) => formData.append('old_images[]', image)); // append the old images to the form data
    console.log(formData);
    const isOk = validateFormData(formData); // validate the form data
    if (isOk !== true) return toast.error(isOk); // if the form data is invalid, show an error toast
    if (!id) return toast.error('Erro ao enviar dados. Tente novamente mais tarde.'); // if the id is invalid, show an error toast

    formData.append('id', id); // append the id to the form data

    const headers = { // set the request headers to change the default content-type
      Accept: "application/json",
      Authorization: "",
    }

    const reqToast = toast.loading('Enviando dados...'); // show a loading toast
    const url = `services/update/${id}`; // set the request url
    const response: any = await Request.Post(url, formData, headers); // send the request to the server
    response?.message
      ? toast.update(reqToast, { // if the server returns an error, show an error toast
        render: 'Erro ao atualizar atração!',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      })
      : toast.update(reqToast, { // if the server returns a success message, show a success toast
        render: 'Atração atualizada com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });
    console.log(response);
  }

  useEffect(() => {
    if (service) {
      const images: string[] = [];
      service.service_images.forEach((image: any) => {
        images.push(image.path);
      });
      setOldImages(images);
    }
  }, [service]);

  return (
    <ContainerHolder>
      <h1>Editar Serviço</h1>
      {service &&
        <ServicesForm
          handleSubmit={handleSubmit}
          startValue={service ? service : undefined}
          oldImages={oldImages}
          setOldImages={setOldImages}
        />
      }
      <div className='d-flex flex-row mt-5'>
        <button
          className='btn btn-primary'
          onClick={() => navigate(-1)}
        >
          Voltar
        </button>
      </div>
      <ToastContainer />
    </ContainerHolder>
  );
}