//* Libraries imports
import { useState } from 'react';
import { X, Pen } from "phosphor-react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Modal from 'react-modal'

//* Components imports
import Request from '../../../utils/Request';
import type { ApiService } from "../../../types/services";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export type User = {
  id: number;
  name: string;
  email: string;
  role: string;
};

interface UserCardProps {
  user: User;
  controls: boolean;
}

export const UserCard = ({ user, controls }: UserCardProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalButtonsDisabled, setModalButtonsDisabled] = useState(false);

  const title = user?.name;
  const titleCut = title?.length > 35
    ? title?.slice(0, 35) + "..."
    : title;

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleDelete = async () => {
    setModalButtonsDisabled(true);
    const toastId = toast.loading('Deletando usuário...');

    const id = user?.id;
    const response = await Request.Delete(`auth/delete/${id}`);
    if (response?.message === "User deleted successfully") {
      toast.dismiss(toastId);
      toast.success(`${response?.user?.name} deletado com sucesso!`);
      closeModal();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }

    toast.dismiss(toastId);
    toast.error('Erro ao deletar usuário!');
    setModalButtonsDisabled(false);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  }
  return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.role}</td>
        {
          controls &&
          <>
            <td>
              <Link to={`/dashboard/user/edit/${user?.id}`}>
                <button className="btn btn-primary">
                  <Pen size={24} />
                </button>
              </Link>
              <button
                onClick={openModal}
                className="btn btn-danger"
              >
                <X size={24} />
              </button>
            </td>
          </>
        }
      </tr>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal de exclusão"
        style={customStyles}
      >
        <h2 className="text-center">Excluir usuário</h2>
        <p className="text-center">
          <strong style={{ color: 'red' }}>
            Você tem certeza que quer deletar o usuário {user?.name}?
          </strong> Esta ação é irreversível.</p>
        <div className="w-100 d-flex justify-content-center gap-5">
          <button
            className="btn btn-outline-primary"
            onClick={closeModal}
            disabled={modalButtonsDisabled}
          >
            Não
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={handleDelete}
            disabled={modalButtonsDisabled}
          >
            Sim
          </button>
        </div>
      </Modal>
    </>
  );
}