//* Libraries imports
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';

//* Redux imports
import { Provider } from 'react-redux';
import { store } from './store/configureStore';

//* Local imports
import './style/global.sass';
import App from './App';

createRoot(document.querySelector('#root') as HTMLElement)
  .render(
    <BrowserRouter>
      <Provider store={store}>
        <StrictMode>
          <App />
        </StrictMode>
      </Provider>
    </BrowserRouter>
  );