//* Libraries imports
import { useState, useCallback, BaseSyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

//* Styles imports
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { MdExitToApp } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';

//* Redux imports
import { useDispatch } from 'react-redux';
import { logout } from '../../store/user';

const dropdownItemStyle = {
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
}

export const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);

  const dispatch = useDispatch();
  const toggle = useCallback(() => setMenu(!menu), [menu]);

  const logout_ = useCallback(() => {
    dispatch(logout()); // Dispatch logout action
    // window.location.reload(); // Reload page
  }, []);

  return (
    <div className="navbar-right d-flex list-inline float-right mb-0">
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block" >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src=""
            onError={(e: BaseSyntheticEvent) => e.target.src = "/default.png"}
            height="50"
            width="50"
            style={{ objectFit: "cover" }}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu end>

          <Link to={`/`}>
            <DropdownItem
              style={dropdownItemStyle}>
              <CgProfile style={{ marginRight: 5 }} />
              Meu perfil
            </DropdownItem>
          </Link>

          <DropdownItem
            tag="a"
            onClick={logout_}
            style={dropdownItemStyle}>
            <MdExitToApp style={{ marginRight: 5 }} />
            Sair
          </DropdownItem>

        </DropdownMenu>
      </Dropdown>
    </div>
  );
}