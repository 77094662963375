type Service = {
  title: string;
  description: string;
  open_hours: string;
  close_hours: string;
  type_of_service: string;
  images: File[];
  old_images?: string[];
};

const validateTextSize = (text: string, min: number, max: number) => {
  return text.length >= min && text.length <= max;
};

const validateService = (service: Service) => {
  const {
    title,
    description,
    open_hours,
    close_hours,
    type_of_service,
    images,
  } = service;

  if (
    title === undefined ||
    description === undefined ||
    open_hours === undefined ||
    close_hours === undefined ||
    type_of_service === undefined ||
    images === undefined
  )
    return "Preencha todos os campos.";

  if (!validateTextSize(title, 3, 255))
    return "O título deve ter entre 3 e 255 caracteres.";
  if (!validateTextSize(description, 0, 4000))
    return "A descrição não pode ter mais de 4000 caracteres.";
  if (open_hours.length !== 5 || close_hours.length !== 5)
    return "O horário deve ser no formato HH:MM.";
  if (!validateTextSize(type_of_service, 3, 255))
    return "O tipo de serviço deve ter entre 3 e 255 caracteres.";

  return true;
};

export const validateFormData = (formData: FormData) => {
  const service: Service = {
    title: formData.get("title") as string,
    description: formData.get("description") as string,
    open_hours: formData.get("open_hours") as string,
    close_hours: formData.get("close_hours") as string,
    type_of_service: formData.get("type_of_service") as string,
    old_images: formData.getAll("old_images[]") as string[],
    images: formData.getAll("images[]") as File[],
  };

  return validateService(service);
};
