//* Libraries imports
import { Plus, X } from "phosphor-react";
import { useState } from 'react';

//* Images url
import { STORAGE_URL } from '../../global';

//* Type imports
import type { ReactNode, ChangeEvent } from 'react';

type Props = {
  oldImages?: string[];
  setOldImages?: (oldImages: string[]) => void;
}

export function ImageSelector({ oldImages = [], setOldImages = () => { } }: Props) {
  const [newImages, setNewImages] = useState<File[]>([]);

  return (
    <div>
      <ImageGalery
        oldImages={oldImages}
        newImages={newImages}
        setOldImages={setOldImages}
        setNewImages={setNewImages}
      >
        <ImageInput
          newImages={newImages}
          setValue={setNewImages}
        />
      </ImageGalery>
      <p>Contagem: {(newImages.length + oldImages.length)}/20</p>
    </div>
  );
}


type ImageGaleryProps = {
  oldImages?: string[];
  newImages: File[];
  setOldImages: (value: string[]) => void;
  setNewImages: (value: File[]) => void;
  children: ReactNode;
}

//* This component is used to show the images that the user has selected to upload
//* or the images that are already in the database
const ImageGalery = ({ oldImages, newImages, setOldImages, setNewImages, children }: ImageGaleryProps) => {
  return (
    <div className="d-flex flex-row flex-wrap gap-2 w-100">
      <>
        {oldImages && oldImages?.map((image, index) => (
          <RenderStringImage
            key={index}
            image={image}
            index={index}
            images={oldImages}
            setImageArray={setOldImages}
          />
        ))}
      </>
      <>
        {newImages && newImages?.map((image, index) => (
          <RenderFileImage
            key={index}
            image={image}
            index={index}
            images={newImages}
            setImageArray={setNewImages}
          />
        ))}
      </>
      <>
        {children} {/* Show the button to add more images */}
      </>
    </div>
  );
}

//* This component is used to render images in the ImageGalery component
type RenderStringImageProps = {
  image: string;
  index: number;
  images: string[];
  setImageArray: (value: string[]) => void;
}

const RenderStringImage = ({ image, index, images, setImageArray }: RenderStringImageProps) => {
  const src = STORAGE_URL + image
  const alt = image;

  const handleImageRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setImageArray && setImageArray(images?.filter((_, i) => i !== index));
  }

  return (
    <div
      key={index}
      className="overflow-hidden rounded-2 border-1 border-secondary position-relative"
      style={{ width: 200, height: 200 }}
    >
      <button
        className="position-absolute top-0 end-0 rounded-2 border-0"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onClick={handleImageRemove}
      >
        <X size={32} color="red" />
      </button>
      <img
        src={src}
        alt={alt}
        width="200"
      />
    </div>
  )
}

type RenderFileImageProps = {
  image: File;
  index: number;
  images: File[];
  setImageArray: (value: File[]) => void;
}

const RenderFileImage = ({ image, index, images, setImageArray }: RenderFileImageProps) => {
  const src = URL.createObjectURL(image);
  const alt = image.name;

  const handleImageRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setImageArray && setImageArray(images?.filter((_, i) => i !== index));
  }

  return (
    <div
      key={index}
      className="overflow-hidden rounded-2 border-1 border-secondary position-relative"
      style={{ width: 200, height: 200 }}
    >
      <button
        className="position-absolute top-0 end-0 rounded-2 border-0"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onClick={handleImageRemove}
      >
        <X size={32} color="red" />
      </button>
      <img
        src={src}
        alt={alt}
        width="200"
      />
    </div>
  )
}

//* This component is used to select the images that the user wants to upload
type ImageInputProps = {
  newImages: File[];
  setValue: (value: File[]) => void;
}
const ImageInput = ({ newImages, setValue }: ImageInputProps) => {

  const handleImageAdd = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // get the files that the user selected
    if (!files) return; // if there are no files, return

    const filesArray = Array.from(files); // convert the files to an array

    //verify if there are repeated images and remove them
    const filteredFilesArray = filesArray.filter((file) => {
      const fileAlreadyExists = newImages.find((image) => image.name === file.name);
      return !fileAlreadyExists;
    });

    setValue(filteredFilesArray); // add the new images to the array of images
  }

  return (
    <label
      className="d-flex flex-column gap-1 justify-content-center align-items-center rounded-2"
      htmlFor="image"
      style={{
        width: '200px',
        height: '200px',
        border: '1px dashed black',
      }}
    >
      Adicionar imagem
      <PlusIcon />
      <input
        type="file"
        name="images[]"
        id="image"
        className="d-none w-100 h-100"
        multiple
        accept="image/*"
        onChange={handleImageAdd}
      />
    </label>
  );
}

const PlusIcon = () => {
  return (
    <div
      className="p-2 rounded-circle bg-black bg-opacity-10 d-flex justify-content-center align-items-center"
      style={{ width: 32, height: 32 }}
    >
      <Plus size={32} color="black" />
    </div>
  );
}