//* Libraries imports
import type { FormEvent } from "react";

//* Components imports
import { DashboardStyledInput, DashboardStyledLabel } from "../../../components/StyledInput/StyledInput";
import { ImageSelector } from "../../../components/ImageSelector/ImageSelector";
import { ApiService } from "../../../types/services";

type ServicesFormProps = {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  startValue?: ApiService;
  oldImages?: string[];
  setOldImages?: (oldImages: string[]) => void;
}

export const ServicesForm = ({ handleSubmit, startValue, oldImages, setOldImages }: ServicesFormProps) => {

  return (
    <form
      className="d-flex flex-column gap-1"
      encType="multipart/form-data"
      method="POST"
      onSubmit={handleSubmit}
    >
      <ImageSelector
        oldImages={oldImages}
        setOldImages={setOldImages}
      />

      <DashboardStyledInput
        type="text"
        id="title"
        text="Nome do serviço"
        placeholder="Alguma coisa"
        defaultValue={startValue?.title}
      />

      <DashboardStyledLabel
        text="Descrição"
        htmlFor="description"
      />
      <textarea
        className="form-control"
        name="description"
        id="description"
        cols={30}
        rows={10}
        placeholder="Descrição do serviço"
        defaultValue={startValue?.description}
      />

      <DashboardStyledLabel
        text="Horário de inicio"
        htmlFor="open_hours"
      />
      <input
        type="time"
        name="open_hours"
        id="open_hours"
        defaultValue={startValue?.open_hours}
      />

      <DashboardStyledLabel
        text="Horário de fim"
        htmlFor="close_hours"
      />
      <input
        type="time"
        name="close_hours"
        id="close_hours"
        defaultValue={startValue?.close_hours}
      />

      <DashboardStyledLabel
        text="Tipo de serviço"
        htmlFor="type_of_service"
      />
      <select
        className="form-control"
        name="type_of_service"
        id="type_of_service"
        defaultValue={startValue?.service_type}
      >
        <option value="banheiro">Banheiro</option>
        <option value="cozinha">Cozinha</option>
        <option value="alimentacao">Alimentação</option>
        <option value="financeiro">Financeiro</option>
        <option value="comercio">Comércio</option>
        <option value="lojas">Lojas</option>
        <option value="outros">Outros</option>
      </select>

      <input
        className="btn btn-success mt-5"
        type="submit"
        value={startValue ? "Atualizar" : "Cadastrar"}
      />

    </form>
  );
}