//* Libraries imports
import { useState, useEffect } from "react";

//* Types
interface WindowSize {
  width: number;
  height: number;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    //* Handler to call on window resize
    const handleResize = () => {
      //* Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    //* Add event listener
    window.addEventListener("resize", handleResize);

    //* Call handler right away so state gets updated with initial window size
    handleResize();

    //* Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
