//* Libraries imports
import { ReactNode } from 'react';
import Logo2 from '../../imgs/logo-thermas.png';
type Props = {
  children: ReactNode;
}

export const LoginModal = ({ children }: Props) => {
  return (
    <div
      className='w-100 h-100 d-flex justify-content-center align-items-center'
      style={{
        backgroundImage: 'linear-gradient(153.46deg, rgba(9, 137, 216, 0.25) -2.41%, rgba(120, 190, 233, 0.25) 102.49%)',
        backdropFilter: 'blur(8px)',
      }}
    >
      <div
        className='d-flex flex-column justify-content-center align-items-center p-4'
        style={{
          border: '2px solid #fff',
          borderRadius: '8px',
          background: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
          gap: '16px',
          width: 'fit-content',
          height: 'fit-content',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <div className='w-100 d-flex justify-content-center align-content-center mb-4'>
          <Logo imgUrl={Logo2} />
        </div>

        <div className='w-100'>
          <h1 style={{ fontFamily: 'Titan One', color: '#002C46', fontSize: '24px' }}>Bem-vindo!</h1>
          <p style={{ fontFamily: 'Roboto', color: '#00436C', fontSize: '16px' }}>Faça login para continuar</p>
        </div>

        {children}

        <div className='w-100'>
          <p style={{ fontFamily: 'Roboto', color: '#00436C', fontSize: '16px' }}>
            <a style={{ textDecoration: 'none', color: '#00436C' }} href="">Esqueci minha senha</a>
          </p>
        </div>
      </div>
    </div>
  );
}

const Logo = ({ imgUrl }: { imgUrl: string }) => {
  return (
    <img
      src={imgUrl}
      loading='lazy'
      alt="Logo"
      className='rounded-2'
      style={{ height: '90px' }}
    />
  );
}