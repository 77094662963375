//* Libraries imports
import { type ChangeEvent, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { ListHeader } from "../../components/ListHeader/ListHeader";
import { LocalLoading } from "../../components/Loading/Loading";
import { ChatCard } from "./components/ChatCard";
import Pagination from "../../components/Pagination/Pagination";

//* Types, Request, vars, imports
import useDebounce from "../../utils/hooks/useDebouncer";
import { useChatList, type Chat } from './utils';

export const ListChats = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const { chats, error, loading, total } = useChatList(page, debouncedSearch);
  const [filteredChats, setFilteredChats] = useState<Chat[]>(chats?.chats || []);

  const isAdm = useSelector((state: any) => state.user.role) === "adm";

  //* Filter chats
  const filterChats = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  //* Update filteredChats when chats change
  useEffect(() => {
    setFilteredChats(chats?.chats || []);
  }, [chats]);

  return (
    <ContainerHolder>
      <ListHeader
        title="Conversas"
        addLink={null}
        description=""
        filterFunction={filterChats}
      />
      <table className="table table-striped table-hover text-center mt-5">
        <thead className="text-center">
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Ip</th>
            <th scope="col">Data</th>
            {
              isAdm && <th scope="col">Ação</th>
            }
          </tr>
        </thead>
        {!loading &&
          <tbody className="w-100">
            {
              filteredChats.map((chat) => (
                <ChatCard key={chat.id} chat={chat} controls={isAdm} />
              ))
            }
          </tbody>
        }
      </table>
      <Pagination page={page} setPage={setPage} total={total} loading={loading} />
      {loading && <LocalLoading />}
      {error && toast.error(error)}
      <ToastContainer />
    </ContainerHolder>
  );
}

