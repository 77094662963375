//* Libraries imports
import { useState, useEffect } from "react";

//* Custom imports
import Request from "../../utils/Request";
import { ApiService } from "../../types/services";

//* Custom hooks
export const useService = (id: string) => {
  const [service, setService] = useState<ApiService>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>(null);

  const fetchService = async () => {
    const response = await Request.Get(`services/${id}`);
    if (response.error) {
      setError(response.error);
      return setLoading(false);
    }
    console.log(response);
    setService(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchService();
  }, []);

  return { service, loading, error };
};
