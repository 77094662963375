//* Libraries imports
import { useState } from 'react';
import { X, Pen, ChatText } from "phosphor-react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Modal from 'react-modal'

//* Utils, Requests
import Request from "../../../utils/Request";

//* Type imports
import type { ApiAttraction, ApiDayOfWeek } from "../../../types/attractions";

Modal.setAppElement('#root'); // This is required by react-modal

const portugueseDaysOfWeek = {
  "sunday": 'Domingo',
  "monday": 'Segunda',
  "tuesday": 'Terça',
  "wednesday": 'Quarta',
  "thursday": 'Quinta',
  "friday": 'Sexta',
  "saturday": 'Sábado',
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

type Props = {
  attraction: ApiAttraction;
  control: boolean;
}

export const AttractionCard = ({ attraction, control }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalButtonsDisabled, setModalButtonsDisabled] = useState(false);
  const title = attraction?.title;
  const titleCut = title?.length > 35
    ? title?.slice(0, 35) + "..."
    : title;

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleDelete = async () => {
    setModalButtonsDisabled(true);
    toast.loading('Deletando atração...');

    const id = attraction?.id;
    await Request.Delete(`attractions/destroy/${id}`);

    toast.dismiss();
    toast.success('Atração deletada com sucesso!');
    closeModal();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  return (
    <>
      <tr>
        <td>{titleCut}</td>
        <td>{attraction?.minimal_height}cm</td>
        <td>{attraction?.minimal_age} anos</td>
        <td>
          {
            attraction?.days_of_week?.map((day: ApiDayOfWeek) => {
              return <span key={day.id}>{portugueseDaysOfWeek[day.weekday]} </span>
            })
          }
        </td>
        {
          control &&
          <>
            <td className="d-flex flex-row gap-2">
              <Link to={`/dashboard/attractions/edit/${attraction?.id}`}>
                <button className="btn btn-primary">
                  <Pen size={20} />
                </button>
              </Link>
              <Link to={`/dashboard/attractions/reviews/${attraction?.id}`}>
                <button className="btn btn-success">
                  <ChatText size={20} />
                </button>
              </Link>
              <button
                onClick={openModal}
                className="btn btn-danger"
              >
                <X size={20} />
              </button>
            </td>
          </>
        }
      </tr>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal de exclusão"
        style={customStyles}
      >
        <h2 className="text-center">Excluir atração</h2>
        <p className="text-center"><strong style={{ color: 'red' }}>Você tem certeza que quer deletar a atração?</strong> Esta ação é irreversível.</p>
        <div className="w-100 d-flex justify-content-center gap-5">
          <button
            className="btn btn-outline-primary"
            onClick={closeModal}
            disabled={modalButtonsDisabled}
          >
            Não
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={handleDelete}
            disabled={modalButtonsDisabled}
          >
            Sim
          </button>
        </div>
      </Modal>
    </>
  );
}