//* Libraries Imports
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//* utils
import { setToken, setEmail, setId, setRole, setUsername } from '../store/user';

//* Open routes  ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓
import { Login } from '../screens/Login/Login';
//* Open routes  ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑

//~ Dashboard routes  ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓
import { Dashboard } from '../screens/Dashboard/Dashboard';

//~ users
import { ListUsers } from './Users/ListUsers';
import { RegisterUsers } from './Users/RegisterUsers';
import { EditUser } from './Users/EditUser';

//~ attractions
import { RegisterAttractions } from './Attractions/RegisterAttractions';
import { ListAttractions } from './Attractions/ListAttractions';
import { EditAttraction } from './Attractions/EditAttraction';
import { AttractionReviews } from './Attractions/AttractionReviews';

//~ services
import { RegisterServices } from './Services/RegisterServices';
import { ListServices } from './Services/ListServices';
import { EditService } from './Services/EditService';

//~ Chats
import { ChatOverview } from './Chat/ChatOverview';
import { ListChats } from './Chat/ListChats';
import { ShowChat } from './Chat/ShowChat';
import { useEffect } from 'react';
//~ Dashboard routes  ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑

//* Protect Route
const PrivateRoute = (props: any) => {
  const token = localStorage.getItem('token'); //verify if user is logged
  return token != null //if user is logged
    ? <Dashboard {...props} /> //go to dashboard
    : <Navigate to="/login" /> //else go to login
}

//* LoginRoute
const LoginRoute = () => {
  const token = useSelector((state: any) => state.token);
  return token == null
    ? <Login />
    : <Navigate to="/" />
}

export const Main = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const storeContent = updateStoreContent();
    if (storeContent) {
      const { email, id, role, token, username } = storeContent;
      dispatch(setToken(token));
      dispatch(setEmail(email));
      dispatch(setUsername(username));
      dispatch(setId(id));
      dispatch(setRole(role));
    }
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/dashboard" element={<PrivateRoute />}>
        <Route path={'list'} element={<ListUsers />} />
        <Route path={'signup'} element={<RegisterUsers />} />

        <Route path={'user/edit/:id'} element={<EditUser />} />

        <Route path={'attractions/register'} element={<RegisterAttractions />} />
        <Route path={'attractions/list'} element={<ListAttractions />} />
        <Route path={'attractions/edit/:id'} element={<EditAttraction />} />
        <Route path={'attractions/reviews/:id'} element={<AttractionReviews />} />

        <Route path={'services/register'} element={<RegisterServices />} />
        <Route path={'services/list'} element={<ListServices />} />
        <Route path={'services/edit/:id'} element={<EditService />} />

        <Route path={'chats'} element={<ChatOverview />} />
        <Route path={'chats/list'} element={<ListChats />} />
        <Route path={'chats/show/:id'} element={<ShowChat />} />
      </Route>

      <Route path="/*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

function updateStoreContent() {
  const email = localStorage.getItem('email');
  const firstTimeLogin = localStorage.getItem('firstTimeLogin');
  const id = localStorage.getItem('id');
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');

  const infos = [email, firstTimeLogin, id, role, token, username];
  const isAllEmpty = infos.every((info) => info == null);

  if (!isAllEmpty) {
    return { email, firstTimeLogin, id, role, token, username };
  }
  return null;
}