
//* Libraries imports
import { NavLink } from "react-router-dom";
import { MagnifyingGlass } from "phosphor-react";

//* Type imports
import type { ChangeEvent } from "react";

type ListHeaderProps = {
  title: string;
  description: string;
  addLink?: string | null;
  filterFunction: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ListHeader = ({ title, description, filterFunction, addLink }: ListHeaderProps) => {
  return (
    <div className='d-flex'>
      <div className='flex-column'>
        <h1 className='header-title'>{title}</h1>
        <p>{description}</p>
        <div className='form-group mb-0 mt-1 position-relative'>
          <input
            className='form-control'
            style={{ borderRadius: '100px' }}
            placeholder='Pesquisar Nome'
            type="text"
            onChange={filterFunction}
          />
          <div className="position-absolute d-flex justify-content-center align-items-center" style={{
            width: '40px',
            height: '40px',
            right: '0px',
            top: '0px',
          }}>
            <MagnifyingGlass size={16} />
          </div>
        </div>
      </div>
      {
        addLink &&
        <div className='align-self-end ms-auto'>
          <NavLink to={addLink}>
            <button className='btn btn-primary btn-lg mt-1'>Adicionar</button>
          </NavLink>
        </div>
      }
    </div>
  );
}