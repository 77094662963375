//* Routes import
import { Main } from './routes/Main';

function App() {
    return (
        <>
            {/* {handleIcon()} */}
            <Main />
        </>
    );
}

export default App;
