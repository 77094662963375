//* Libraries imports
import { useEffect, useState, FormEvent } from 'react';

//* Components imports
import { StyledPasswordInput } from '../StyledInput/StyledInput';
import { FormStyledButton } from '../StyledButton/StyledButtons';

//* Redux
import { useDispatch } from 'react-redux';
import { logout } from '../../store/user';

//* Utils
import Request from '../../utils/Request';

type Credentials = {
  actualPassword: string,
  newPassword: string;
  confirmPassword: string;
}

type Props = {
  loading: (e: boolean) => void;
}

export const FirstLoginForm = ({ loading }: Props) => {
  const [credentials, setCredentials] = useState<Credentials>({
    newPassword: '', confirmPassword: '', actualPassword: ''
  });
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [firstTimeLogin, setFirstTimeLogin] = useState(true);

  const dispatch = useDispatch();

  //* Handle form changes
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setCredentials({ ...credentials, [name]: value });
  }

  //* Update the user password
  const UpdatePassword = async () => {
    loading(true);
    const id = localStorage.getItem('id');
    const user = {
      id,
      password: credentials.actualPassword,
      newpassword: credentials.newPassword,
    }
    const body = JSON.stringify(user);
    const url = `auth/firstlogin/${user.id}`;
    try {
      const response: any = await Request.Post(url, body);
      if (response.message === "Invalid password") return setErrorMsg("Senha atual incorreta.");
      const tmpFirstTime = await response?.user?.first_time_login;
      setFirstTimeLogin(await tmpFirstTime);
    } catch (error) {
      console.log(error);
    } finally {
      loading(false);
    }
  }

  //* Handle form submit
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { newPassword, confirmPassword } = credentials;
    //* Verify if the password is valid
    const passwordValidation = validatePasswod(newPassword);
    if (passwordValidation !== true) {
      return setErrorMsg(passwordValidation);
    }
    //* Verify if the password and the confirm password are the same
    if (newPassword !== confirmPassword) {
      return setErrorMsg('As senhas não são iguais.');
    }
    //* If the password is valid, send the new password to the API
    UpdatePassword();

    //* Clear the error message
    setErrorMsg('');
  }

  //* Verify if the passwords are equal
  useEffect(() => {
    const { newPassword, confirmPassword } = credentials;
    if (newPassword !== confirmPassword) {
      return setErrorMsg('As senhas não são iguais.');
    }
    setErrorMsg('');
  }, [credentials]);

  useEffect(() => {
    if (firstTimeLogin === false) {
      dispatch(logout());
      window.location.reload();
    }
  }, [firstTimeLogin]);

  const showErrorMsg = errorMsg !== ''
    ? { height: '20px' }
    : { height: 'none' };

  return (
    <form
      className='gap-3 d-flex flex-column justify-content-center align-content-center'
      onSubmit={handleSubmit}
    >
      <StyledPasswordInput
        placeholder='**********'
        id="actualPassword"
        name='actualPassword'
        msg="Senha atual"
        required={true}
        onChange={handleChange}
      />

      <StyledPasswordInput
        placeholder='**********'
        id="newPassword"
        name="newPassword"
        msg="Nova senha"
        required={true}
        onChange={handleChange}
      />

      <StyledPasswordInput
        placeholder='**********'
        id="confirmPassword"
        name="confirmPassword"
        msg="Confirme a nova senha"
        required={true}
        onChange={handleChange}
      />

      <FormStyledButton type='submit' text='Atualizar senha' />

      <p style={{ ...showErrorMsg, fontFamily: 'Roboto', color: 'red' }}>{errorMsg}</p>
    </form>
  );
}

//* Verify if the password is valid
function validatePasswod(password: string): true | string {
  const containsNumber = /\d/
  const containsSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/
  const containsUpperCase = /[A-Z]/
  const containsLowerCase = /[a-z]/
  const isLongEnough = /.{8,255}/

  if (!containsNumber.test(password)) return "A senha deve conter pelo menos um número.";
  if (!containsSpecialCharacter.test(password)) return "A senha deve conter pelo menos um caractere especial.";
  if (!containsUpperCase.test(password)) return "A senha deve conter pelo menos uma letra maiúscula.";
  if (!containsLowerCase.test(password)) return "A senha deve conter pelo menos uma letra minúscula.";
  if (!isLongEnough.test(password)) return "A senha deve conter entre 8 e 255 caracteres.";
  return true;
}