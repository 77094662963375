//* Libraries imports
import { useEffect, useState, FormEvent, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

//* Reducer
import { useDispatch } from 'react-redux';
import { setEmail, setUsername, setToken, setId, setRole } from '../../store/user';

//* Components imports
import { LoginModal } from '../../components/LoginModal/LoginModal';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { FirstLoginModal } from '../../components/FirstLoginModal/FirstLoginModal';
import { FirstLoginForm } from '../../components/FirstLoginForm/FirstLoginForm';
import { Loading } from '../../components/Loading/Loading';

//* import types and variables
import { URL } from '../../global';
import type { LoginCredentials } from '../../types/user';

export function Login() {
  const [loginCredentials, setLoginCredentials] = useState<LoginCredentials>({
    email: '',
    password: '',
    token: null,
    username: "",
    firstTimeLogin: null,
    id: null,
    role: null,
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);


  //* Redux
  const dispatch = useDispatch();

  //* Verify if the user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    const tmpEmail = localStorage.getItem('email');
    const tmpUsername = localStorage.getItem('username');
    const tmpId = localStorage.getItem('id');
    const role = localStorage.getItem('role');

    const tmpFirstTimeLogin = JSON.parse(localStorage.getItem('firstTimeLogin') + "");
    if (token && tmpEmail && tmpUsername && role) {
      setLoginCredentials({
        ...loginCredentials,
        token,
        email: tmpEmail,
        username: tmpUsername,
        id: tmpId,
        firstTimeLogin: tmpFirstTimeLogin,
        role: role,
      });
    }
    setLoading(false);
  }, []);

  //* Conect to API and try login
  const tryLogin = async () => {
    try {
      fetch(URL + 'api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(loginCredentials)
      }).then(async (response: any) => {
        const data = await response.json();
        console.log(data?.user?.first_time_login);
        if (data?.message === "User not found") return setErrorMsg("Usuário não encontrado.");
        if (data?.message === "Invalid password") return setErrorMsg("Senha incorreta.");
        const tmpId = await data?.user?.id;
        const tmpToken = await data?.access_token;
        const username = await data?.user?.name;
        const tmpRole = await data?.user?.role;
        const firstTimeLogin = await data?.user?.first_time_login;
        setLoginCredentials({
          ...loginCredentials,
          id: await tmpId,
          token: await tmpToken,
          username: await username,
          firstTimeLogin: await firstTimeLogin,
          role: await tmpRole,
        });
      }).finally(() => { setLoading(false) });
    } catch {
      console.log("deu pau!");
    }
  };

  //* Handle the change of the input fields
  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    tryLogin();
  }

  useEffect(() => {
    const { token, id, email, username, firstTimeLogin, role } = loginCredentials;
    if (token != null && id != null) {
      //* Save login data in the redux store
      dispatch(setToken(token));
      dispatch(setEmail(email));
      dispatch(setUsername(username));
      dispatch(setId(id));
      dispatch(setRole(role));

      //* save the login data in the local storage
      localStorage.setItem('token', token);
      localStorage.setItem('email', email);
      localStorage.setItem('username', username);
      localStorage.setItem('id', id);
      localStorage.setItem('firstTimeLogin', firstTimeLogin + "");
      localStorage.setItem('role', role + "");

      //* redirect to home if first time login is false
      firstTimeLogin === true || null
        ? setRedirect(false)
        : setRedirect(true);
    }
  }, [loginCredentials]);

  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center position-relative'>
      {redirect && <Navigate to="/dashboard" />}
      {loading && <Loading />}

      <Background>
        {!loginCredentials.firstTimeLogin &&
          <LoginModal>
            <LoginForm
              handler={handleLogin}
              credentials={loginCredentials}
              setCredentials={setLoginCredentials}
              errorMsg={errorMsg}
            />
          </LoginModal>
        }
        {
          loginCredentials.firstTimeLogin &&
          <FirstLoginModal>
            <FirstLoginForm loading={(e: boolean) => { setLoading(e) }} />
          </FirstLoginModal>
        }
      </Background>
    </div>
  );
}

//* Background
const Background = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className='w-100 h-100 d-flex justify-content-center align-items-center top-0 start-0 position-absolute overflow-hidden bg-dark'
      style={{
        background: '#fff url(/fundo.jpg) center center/cover no-repeat',
      }}
    >
      {children}
    </div>
  );
}