//*  Libraries imports
import { useState, useEffect } from "react";

//* Types, Request, vars, imports
import Request from "../../utils/Request";

//* Types
export type Option = {
  id: string;
  option_id: string;
  option: {
    id: string;
    code: string;
    text: string;
    created_at: string;
    updated_at: string;
    deleted_at: null;
  };
  last_option_id: string;
  last_option: {
    id: string;
    code: string;
    text: string;
    created_at: string;
    updated_at: string;
    deleted_at: null;
  };
  link: null;
  chat_id: string;
  text: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
};

export type Chat = {
  id: string;
  name: string;
  email: string | null;
  ip: string;
  options?: Option[];
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export type ApiChatList = {
  status: boolean;
  total: number;
  page: number;
  chats: Chat[];
};

export type ApiChatShow = {
  status: boolean;
  total: number;
  chat: Chat[];
};

export type ShowedOptions = {
  count: number;
  text: string;
};

export type ChatData = {
  total: number;
  status: boolean;
  showed_options: ShowedOptions[];
  data: [
    {
      count: number;
      option_id: string;
      option: {
        id: string;
        code: string;
        text: string;
        created_at: string;
        updated_at: string;
        deleted_at: null;
      };
    }
  ];
};

export type ParsedChatData = {
  name: string;
  uses: number;
};

//*-----------------------------* Custom Hooks *-----------------------------*//
//* Get chat data from the API
export function useChatData() {
  const [chatData, setChatData] = useState<ChatData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchChatData = async () => {
      setLoading(true);
      try {
        const data = await Request.Get("chat/chart");
        if (data && !(data instanceof Response)) {
          setChatData(data);
        }
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };
    fetchChatData();
  }, []);

  useEffect(() => {
    console.log("chatData", chatData);
  }, [chatData]);

  return { chatData, loading, error };
}

export function useParsedChatData() {
  const { chatData, error, loading } = useChatData();
  const [parsedData, setParsedData] = useState<ParsedChatData[] | null>(null);

  useEffect(() => {
    if (chatData) {
      setParsedData(parseChatData(chatData));
    }
  }, [chatData]);

  return { chatData, parsedData, error, loading };
}

//* Get chats in the API
export function useChatList(page: number, search_term: string = "") {
  const [total, setTotal] = useState<number>(0);
  const [chats, setChats] = useState<ApiChatList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchData = async () => {
    console.log("fetching page ", page, "...");
    setLoading(true);
    try {
      const data = await Request.Get(
        `chat/list?page=${page}&limit=10&search_term=${search_term}`
      );
      if (data && !(data instanceof Response)) {
        setChats(data);
        //@ts-ignore
        setTotal(data?.total);
        console.log("data", data);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  //fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  //if page or search_term changes, fetch new data
  useEffect(() => {
    fetchData();
  }, [page, search_term]);

  return { chats, loading, error, total };
}

//* Get chat in the API
export function useChatShow(id: string) {
  const [chat, setChat] = useState<ApiChatShow | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchChat = async () => {
      setLoading(true);
      try {
        const body = JSON.stringify({ id });
        const data = await Request.Post("chat/show", body);
        if (data && !(data instanceof Response)) {
          setChat(data);
          console.log("data", data);
        }
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };
    fetchChat();
  }, [id]);

  return { chat, loading, error };
}

//*-----------------------------* Utils *-----------------------------*//
//* Parse chat chart data from api to recharts format
export function parseChatData(data: ChatData) {
  let parsedData: ParsedChatData[] = [];

  //if chat.option.text is larger than 20 characters, truncate it and add ...

  data.data.forEach((chat) => {
    parsedData.push({
      name: specificHtmlSanitizer(chat.option.text),
      uses: chat.count,
    });
  });

  return parsedData;
}

//* Chat Options Parser to Mermaid
export function optionsParser(options: Option[]) {
  let boxes: string[] = [];
  let arrows: string[] = [];
  let leftArrows: string[] = [];
  let rightArrows: string[] = [];
  console.log("options:", options);

  if (!options) throw "No options";

  //* add boxes
  options.forEach((option) => {
    if (!boxes.includes(option?.last_option?.id)) {
      boxes.push(option?.last_option?.id);
      leftArrows.push(option?.last_option?.id);
    }
    if (!boxes.includes(option?.option?.id)) {
      boxes.push(option?.option?.id);
      rightArrows.push(option?.option?.id);
    }
  });

  //* add boxes text
  boxes.forEach((box, index) => {
    const tmpText = options[index]?.last_option?.text || "fim";
    boxes[index] = `${box}("${specificHtmlSanitizer(tmpText)}")`;
  });

  console.log(boxes);

  //* add arrows
  options.forEach((option, index) => {
    const from = option?.last_option?.id || "";
    const to = option?.option?.id || "";
    if (from !== "" && to !== "")
      arrows.push(`${from} -- "${index + 1}" --> ${to}`);
  });

  //* generate mermaid string
  let mermaidString = `flowchart TB\n`;
  boxes.forEach((box) => {
    mermaidString += `${box}\n`;
  });

  arrows.forEach((arrow) => {
    mermaidString += `${arrow}\n`;
  });

  return mermaidString;
}

//* All the functions below are used to sanitize the text from the API
export function specificHtmlSanitizer(text: string) {
  return replacerAsp(placeEnter(htmlSanitizer(removeHTML(text))));
}

//* replace " from '
function replacerAsp(text: string) {
  return text.replace(/"/g, "'");
}

//* this function is used to remove html tags from a string.
export function removeHTML(text: string) {
  const doc = new DOMParser().parseFromString(text, "text/html");
  return doc.body.textContent || "";
}

//* every 50 char, place an \n, but, if in the middle of a word, place it before the next word
function placeEnter(text: string) {
  let newText = "";
  let words = text.split(" ");
  let count = 0;
  words.forEach((word) => {
    if (count + word.length > 50) {
      newText += `\n${word} `;
      count = word.length;
    } else {
      newText += `${word} `;
      count += word.length;
    }
  });
  return newText;
}

//* this function is used to sanitize html tags from a string.
function htmlSanitizer(text: string) {
  let div = document.createElement("div");
  div.innerText = text;
  return div.innerHTML;
}
