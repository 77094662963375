//* Libraries imports
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

//* utils imports
import Logo2 from '../../imgs/logo-thermas.png';

//* Styles imports
import { FaFolderPlus } from 'react-icons/fa';
import { MdCopyright } from 'react-icons/md';
import './styles/scrollBar.css';
const sidebarBg = './bg1.jpg';

type Props = {
  image?: string;
  collapsed?: boolean;
  toggled?: boolean;
  handleToggleSidebar?: (value: boolean) => void;
  handleCollapsedChange?: (value: boolean) => void;
}

export const Sidebar = ({ image, collapsed, toggled, handleToggleSidebar }: Props) => {
  const role = useSelector((state: any) => state.user);

  return (
    <ProSidebar
      image={image ? sidebarBg : undefined}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      style={{ position: 'fixed', height: '100vh' }}
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader style={{ background: '#222d32', height: '70px' }}>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            // backgroundColor: 'white',
          }}
        >
          {collapsed === true && <span>
            <img src={Logo2} alt="" height='60' style={{
              height: '60px',
              width: '90%',
              objectFit: 'contain'
            }} />
          </span>}
          {collapsed === false && <i>
            <img
              className="img-fluid"
              src={Logo2} 
              alt=""
              height="100"
              style={{ height: '40px' }} />
          </i>}
        </div>
      </SidebarHeader>
      <SidebarContent className='scrollMenu' style={{ background: '#222d32', overflowY: 'auto' }}>
        <Menu iconShape="circle">


          {/* <MenuItem icon={<FaGem />}> {'Cadastros'}</MenuItem> */}
          <SubMenu title="Usuários" icon={<FaFolderPlus />}>
            <MenuItem><NavLink to="/dashboard/list">Lista de usuários</NavLink></MenuItem>
            {
              role.role === 'adm' &&
              <MenuItem><NavLink to="/dashboard/signup">Cadastrar usuário</NavLink></MenuItem>
            }
          </SubMenu>
          {/* <SubMenu title="Atrações" icon={<FaFolderPlus />}>
            {
              role.role === 'adm' &&
              <MenuItem><NavLink to="/dashboard/attractions/register">Cadastrar atração</NavLink></MenuItem>
            }
            <MenuItem><NavLink to="/dashboard/attractions/list">Lista de atrações</NavLink></MenuItem>
          </SubMenu>
          <SubMenu title="Serviços" icon={<FaFolderPlus />}>
            {
              role.role === 'adm' &&
              <MenuItem><NavLink to="/dashboard/services/register">Cadastrar serviço</NavLink></MenuItem>
            }
            <MenuItem><NavLink to="/dashboard/services/list">Lista de serviços</NavLink></MenuItem>
          </SubMenu> */}
          <SubMenu title="ChatBot" icon={<FaFolderPlus />}>
            <MenuItem><NavLink to="/dashboard/chats">Visão geral</NavLink></MenuItem>
            <MenuItem><NavLink to="/dashboard/chats/list">Lista de conversas</NavLink></MenuItem>
          </SubMenu>
        </Menu>

      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center', background: '#222d32' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://www.termas.com.br/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <MdCopyright />
            <span> {'CopyRight Thermas 2023'}</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

