// Component used to edit an attraction. At start, it loads the attraction data from the server and shows it in the form.
// When the user clicks on the "Save" button, the data is sent to the server and the attraction is updated.

//* Libraries Imports
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

//* Request, types, utils imports
import Request from '../../utils/Request';
import { validateFormData, useAttraction } from './utils';
import type { FormEvent } from 'react';
import type { ApiAttraction, ApiImage } from '../../types/attractions';

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { AttractionsForm } from './components/AttractionsForm';

export const EditAttraction = () => {
  const { id } = useParams(); // get the id from the url
  const navigate = useNavigate(); // used to navigate to another page
  const [oldImages, setOldImages] = useState<string[]>([]); // set the old images to an empty array [
  const { attraction, loading } = useAttraction(id || "");

  //* Redirect to dashboard if user is not admin
  const role = useSelector((state: any) => state.user.role);
  if (role !== 'adm') navigate('/dashboard');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    console.log(event.target);

    const formData = new FormData(event.target as HTMLFormElement); // get the form data
    oldImages.forEach((image) => formData.append('old_images[]', image)); // append the old images to the form data
    console.log(formData);
    const isOk = validateFormData(formData); // validate the form data
    if (isOk !== true) return toast.error(isOk); // if the form data is invalid, show an error toast

    if (!id) return toast.error('Erro ao enviar dados. Tente novamente mais tarde.'); // if the id is not defined, show an error toast

    formData.append('id', id); // append the id to the form data

    const headers = { // set the headers to change the content type to multipart/form-data on Request.Post
      Accept: "application/json",
      Authorization: "",
    }

    const reqToast = toast.loading('Enviando dados...'); // show a loading toast
    const url = `attractions/update/${id}`; // set the url to send the request

    const response: any = await Request.Post(url, formData, headers); // send the request to the server
    response?.message
      ? toast.update(reqToast, { // show an error toast
        render: 'Erro ao atualizar atração!',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      })
      : toast.update(reqToast, { // show a success toast
        render: 'Atração atualizada com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });
    console.log(response);
  }

  useEffect(() => {
    if (attraction) {
      const images: string[] = [];
      attraction.images.forEach((image: ApiImage) => {
        images.push(image.path);
      });
      setOldImages(images);
    }
  }, [attraction]);

  useEffect(() => {
    console.log(oldImages);
  }, [oldImages]);

  return (
    <ContainerHolder>
      <h1>Editar Atração</h1>
      {attraction &&
        <AttractionsForm
          handleSubmit={handleSubmit}
          startValue={attraction ? attraction : undefined}
          oldImages={oldImages}
          setOldImages={setOldImages}
        />
      }
      <div className='d-flex flex-row mt-5'>
        <button
          className='btn btn-primary'
          onClick={() => navigate(-1)}
        >
          Voltar
        </button>
      </div>
      <ToastContainer />
    </ContainerHolder>
  );
}