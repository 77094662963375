type Service = {
  title: string;
  description: string;
  open_hours: string;
  close_hours: string;
  type_of_service: string;
  images: File[];
};

export const validateFormData = (form: FormData) => {
  const Service: Service = {
    title: form.get("title") as string,
    description: form.get("description") as string,
    open_hours: form.get("open_hours") as string,
    close_hours: form.get("close_hours") as string,
    type_of_service: form.get("type_of_service") as string,
    images: form.getAll("images") as File[],
  };
  return validateService(Service);
};

const validateService = (service: Service): string | boolean => {
  const {
    title,
    description,
    open_hours,
    close_hours,
    type_of_service,
    images,
  } = service;
  if (
    !title ||
    !description ||
    !open_hours ||
    !close_hours ||
    !type_of_service
  ) {
    return "Preencha todos os campos";
  }

  if (title === "") return "Preencha o campo título";
  if (description === "") return "Preencha o campo descrição";
  if (open_hours === "") return "Preencha o campo horário de abertura";
  if (close_hours === "") return "Preencha o campo horário de fechamento";
  if (type_of_service === "") return "Preencha o campo tipo de serviço";

  //* String sizes validation
  if (title.length > 255 || title.length < 3)
    return "O título deve ter entre 3 e 255 caracteres";
  if (description.length > 4000)
    return "A descrição deve ter até 4000 caracteres";
  if (open_hours.length > 5)
    return "O horário de abertura deve ter até 5 caracteres";
  if (close_hours.length > 5)
    return "O horário de fechamento deve ter até 5 caracteres";

  //* Images validation
  if (images.length > 5) return "Você pode enviar até 5 imagens";
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const validation = imageValidation(image, 1000000);
    if (validation !== true) return validation;
  }

  return true;
};

const imageValidation = (image: File, fileSize: number) => {
  const types = ["image/png", "image/jpeg", "image/jpg"];
  if (!types.includes(image.type)) return "Formato de imagem inválido";
  if (image.size > fileSize) return "O tamanho da imagem é muito grande";
  return true;
};
