//* Libraries imports
import { useEffect, useState } from 'react';
import { CircleNotch } from "phosphor-react";

//* Styles imports
import "./style.sass";

export const Loading = () => {
  const [loadingTextDots, setLoadingTextDots] = useState("");

  useEffect(() => {
    if (loadingTextDots.length <= 3) {
      setTimeout(() => {
        setLoadingTextDots(loadingTextDots + ".")
      }, 700);
    } else {
      setLoadingTextDots("");
    }
  }, [loadingTextDots]);

  return (
    <div className="loadingHolder">
      <CircleNotch className="circle" size={100} weight="bold" />
      <p>Tentando fazer login, aguarde{loadingTextDots}</p>
    </div>
  );
}


export const LocalLoading = () => {
  return (
    <div className="localLoadingHolder">
      <CircleNotch className="circle" size={100} weight="bold" />
    </div>
  );
}