//* Libraries imports
import { useState } from 'react';
import { X, Pen } from "phosphor-react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Modal from 'react-modal'

//* Components imports
import Request from '../../../utils/Request';
import type { ApiService } from "../../../types/services";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

type ServiceCardProps = {
  service: ApiService;
  controls: boolean;
}

export const ServiceCard = ({ service, controls }: ServiceCardProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalButtonsDisabled, setModalButtonsDisabled] = useState(false);

  const title = service?.title;
  const titleCut = title?.length > 35
    ? title?.slice(0, 35) + "..."
    : title;

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleDelete = async () => {
    setModalButtonsDisabled(true);
    toast.loading('Deletando serviço...');

    const id = service?.id;
    await Request.Delete(`services/destroy/${id}`);

    toast.dismiss();
    toast.success('Atração deletada com sucesso!');
    closeModal();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  return (
    <>
      <tr>
        <td>{titleCut}</td>
        <td>{service?.service_type}</td>
        {
          controls &&
          <>
            <td className="gap-2 d-flex flex-row justify-content-center">
              <Link to={`/dashboard/services/edit/${service?.id}`}>
                <button className="btn btn-primary">
                  <Pen size={24} />
                </button>
              </Link>
              <button
                onClick={openModal}
                className="btn btn-danger"
              >
                <X size={24} />
              </button>
            </td>
          </>
        }
      </tr>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal de exclusão"
        style={customStyles}
      >
        <h2 className="text-center">Excluir serviço</h2>
        <p className="text-center">
          <strong style={{ color: 'red' }}>
            Você tem certeza que quer deletar esse serviço?
          </strong> Esta ação é irreversível.</p>
        <div className="w-100 d-flex justify-content-center gap-5">
          <button
            className="btn btn-outline-primary"
            onClick={closeModal}
            disabled={modalButtonsDisabled}
          >
            Não
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={handleDelete}
            disabled={modalButtonsDisabled}
          >
            Sim
          </button>
        </div>
      </Modal>
    </>
  );
}