//* Libraries imports
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { LocalLoading } from "../../components/Loading/Loading";
import { AttractionCard } from "./components/AttractionCard";
import { ListHeader } from "../../components/ListHeader/ListHeader";

//* Utils, Requests
import Request from "../../utils/Request";
import { cleanUpSpecialChars } from "../../utils/stringUtils";

//* Types
import type { ChangeEvent } from "react";
import type { ApiAttraction } from "../../types/attractions";


export const ListAttractions = () => {
  const [loading, setLoading] = useState(true);
  const [attractions, setAttractions] = useState<ApiAttraction[]>([]);
  const [filteredAttractions, setFilteredAttractions] = useState<ApiAttraction[]>([]);

  const isAdm = useSelector((state: any) => state.user.role) === 'adm';

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = cleanUpSpecialChars(event.target.value.toLowerCase());
    const filtered = attractions.filter((attraction) => {
      return cleanUpSpecialChars(attraction.title.toLowerCase()).includes(searchTerm);
    });
    setFilteredAttractions(filtered);
  }

  const getAttractions = async () => {
    try {
      setLoading(true);
      const response = await Request.Get('attractions/list');
      if (response === undefined) throw new Error();
      setAttractions(response);
    } catch {
      toast.error('Erro ao carregar atrações');
      setAttractions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (attractions.length === 0) {
      setLoading(true);
      getAttractions();
    }
  }, []);

  useEffect(() => {
    console.log(attractions);
    if (attractions.length > 0) {
      setFilteredAttractions(attractions);
      setLoading(false);
    }
  }, [attractions]);

  return (
    <ContainerHolder>
      <ListHeader
        title="Lista de atrações"
        description="Aqui são listados todas as atrações cadastradas no sistema."
        filterFunction={handleSearch}
        addLink={isAdm ? "/dashboard/attractions/register" : null}
      />
      <table className="table table-striped table-hover text-center mt-5">
        <thead className="text-center">
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Altura minima</th>
            <th scope="col">Idade minima</th>
            <th scope="col">Dias da semana</th>
            {
              isAdm &&
              <th scope="col">Ação</th>
            }
          </tr>
        </thead>
        {!loading &&
          <tbody className="w-100">
            {filteredAttractions?.map((attraction) => (
              <AttractionCard
                key={attraction.id}
                attraction={attraction}
                control={isAdm}
              />
            ))}
          </tbody>
        }
      </table>
      {loading && <LocalLoading />}
      <ToastContainer />
    </ContainerHolder>
  );
}