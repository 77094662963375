// List all services registered in the system and allow the user to search for them

//* Libraries imports
import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from 'react';
import { ContainerHolder } from '../../components/ContainerHolder/ContainerHolder';
import { useSelector } from 'react-redux';

//* Components imports
import { ServiceCard } from './components/ServiceCard';
import { LocalLoading } from '../../components/Loading/Loading';
import { ListHeader } from '../../components/ListHeader/ListHeader';

//* Type, Request imports
import Request from '../../utils/Request';
import type { ChangeEvent } from 'react';
import type { ApiService } from '../../types/services';


export const ListServices = () => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<ApiService[]>([]);
  const [filteredServices, setFilteredServices] = useState<ApiService[]>([]);

  const isAdm = useSelector((state: any) => state.user.role) === 'adm';

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => { // Search for services by name
    const searchTerm = event.target.value.toLowerCase(); // Get search term
    const filtered = services.filter((service) => { // Filter services
      return service.title.toLowerCase().includes(searchTerm); // Return services that match the search term
    });
    setFilteredServices(filtered); // Set filtered services
  }

  // Get all services from the API
  const getServices = async () => {
    try {
      setLoading(true);
      const response = await Request.Get('services/list');
      if (response === undefined) throw new Error();
      setServices(response);
    } catch {
      toast.error('Erro ao carregar atrações');
      setServices([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // If services is empty, get services from the API
    if (services.length === 0) {
      setLoading(true);
      getServices(); // Get services from the API
    }
  }, []);

  useEffect(() => {
    // If services is not empty, set filtered services to services
    if (services.length > 0) {
      setFilteredServices(services); // Set filtered services to services
      setLoading(false);
    }
  }, [services]);

  return (
    <ContainerHolder>
      <ListHeader
        title="Lista de Serviços"
        description="Aqui são listados todas os serviços cadastrados no sistema."
        addLink={isAdm ? "/dashboard/services/register" : null}
        filterFunction={handleSearch}
      />

      <table className="table table-striped table-hover text-center mt-5">
        <thead className="text-center">
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Tipo de serviço</th>
            {
              isAdm && <th scope="col">Ações</th>
            }
          </tr>
        </thead>
        {!loading &&
          <tbody className="w-100">
            {filteredServices?.map((service) => (
              <ServiceCard 
              key={service.id}
              service={service}
              controls={isAdm}
              />
            ))}
          </tbody>
        }
      </table>
      {loading && <LocalLoading />}
      <ToastContainer />
    </ContainerHolder>
  );
}