//* Libraries Imports
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

//* Components imports
import { DashboardStyledInput, DashboardStyledLabel, DashboardStyledParagraph } from '../../components/StyledInput/StyledInput';
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";

//* Request
import Request from '../../utils/Request';

//* Type imports
import type { FormInputChange, FormSelectChange } from '../../types/global';
import type { AccountEditCredentials, Roles } from '../../types/user';

export const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [startValues, setStartValues] = useState<any>(null);
  const [user, setUser] = useState<AccountEditCredentials>({
    name: '', email: '', password: '', confirmPassword: '', role: 'geral', id: id || ""
  });
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  //* Redirect to dashboard if user is not admin
  const role = useSelector((state: any) => state.user.role);
  if (role !== 'adm') navigate('/dashboard');

  //* Handle inputs changes
  const handleInputChange = (event: FormInputChange) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  const handleSelectChange = (event: FormSelectChange) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  }

  //* Handle form submit
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isAllFieldsOk = verifyLoginInputs(user); //* Verify if the login credentials are ok

    if (isAllFieldsOk === true) { //* If the login credentials are ok, send the request
      tryUpdateAccount();
    }
    typeof isAllFieldsOk == 'string' //* If the login credentials are not ok, update the error message
      ? setErrorMsg(isAllFieldsOk)
      : setErrorMsg('');
  };

  //* Try to create an account
  const tryUpdateAccount = async () => {
    const loadingMsg = toast.loading('Atualizando dados da conta...');
    setLoading(true);
    try {
      const body = JSON.stringify(user);
      console.log(body);
      const response: any = await Request.Post(`auth/update/${id}`, body);
      const message = await response?.message;
      console.log(message);

      if (message === "Successfully updated user!") {
        toast.update(loadingMsg,
          {
            render: 'Conta atualizada com sucesso!',
            type: 'success',
            isLoading: false,
            autoClose: 2000
          })
        setTimeout(() => {
          navigate('/dashboard/list');
        }, 2000);
      } else {
        return updateSignUpErrorToast(message, loadingMsg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //* If error message update, show the error on toast
  useEffect(() => {
    if (errorMsg !== '') toast.error(errorMsg);
  }, [errorMsg]);

  //* set the start values of the inputs, from the api
  useEffect(() => {
    if (startValues) {
      setUser({
        name: startValues?.name,
        email: startValues?.email,
        password: '',
        confirmPassword: '',
        role: startValues?.role,
        id: id || ""
      });
    }
  }, [startValues]);

  useEffect(() => {
    setLoading(true);
    //fetch user data from api
    const fetchData = async () => {
      const toastId = toast.loading('Carregando dados...');
      const response = await Request.Get(`auth/show/${id}`);
      const tmpUser = response?.user;
      setStartValues({
        name: tmpUser?.name as string,
        email: tmpUser?.email as string,
        role: tmpUser?.role as Roles,
      });
      toast.update(toastId, {
        render: 'Dados carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 2000
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <ContainerHolder>
      <form className='d-flex flex-column gap-1' onSubmit={handleSubmit}>
        <h2>Editar usuário</h2>

        <DashboardStyledInput
          placeholder='Nome da pessoa'
          onChange={(e) => handleInputChange(e)}
          type="text"
          id="name"
          text='Nome '
          defaultValue={startValues?.name || ""}
        />

        <DashboardStyledInput
          placeholder='Email da pessoa'
          onChange={(e) => handleInputChange(e)}
          type="email"
          id="email"
          text='Email '
          defaultValue={startValues?.email || ""}
        />

        <DashboardStyledLabel text="Senha deve conter: " htmlFor='' required={false} />
        <div className='w-100 d-flex flex-row'>
          <div className='w-50 d-flex flex-column'>
            <DashboardStyledParagraph text="* entre 8 e 255 caracteres." />
            <DashboardStyledParagraph text="* 1 letra maiúscula." />
            <DashboardStyledParagraph text="* 1 letra minúscula." />
          </div>
          <div>
            <DashboardStyledParagraph text="* 1 número." />
            <DashboardStyledParagraph text="* 1 caractere especial." />
          </div>
        </div>
        <DashboardStyledInput
          placeholder='************'
          onChange={(e) => handleInputChange(e)}
          type="password"
          id="password"
          text="Nova senha "
          required={false}
        />

        <DashboardStyledInput
          placeholder='************'
          onChange={(e) => handleInputChange(e)}
          type="password"
          id="confirmPassword"
          text='Confirmar nova senha '
          required={false}
        />

        <DashboardStyledLabel text="Tipo de usuário " htmlFor='userRole' />
        <select
          className='form-select'
          name="role"
          id="userRole"
          value={startValues?.role || ""}
          onChange={(e) => handleSelectChange(e)}
        >
          <option value="geral">Colaborador geral</option>
          <option value="adm">Administrador</option>
          <option value="ti">TI</option>
          <option value="marketing">Marketing</option>
          <option value="gestao">Gestão</option>
        </select>

        <div className='d-flex flex-row justify-content-between mt-3'>
          <button
            className='btn btn-info btn-lg'
            onClick={() => { navigate(-1) }}
            disabled={loading}
          >Voltar</button>
          <input
            className='btn btn-primary btn-lg'
            style={{ background: "#157347" }}
            type="submit"
            value="Editar"
            disabled={loading}
          />
        </div>

        <ToastContainer pauseOnHover={true} />
      </form>
    </ContainerHolder>
  );
}

type error = {
  [key: string]: string;
}

//* Update toast if user creation fails or if everythin is ok
function updateSignUpErrorToast(input: string, toastId: any) {
  const errors: error[] = [{ "The email has already been taken.": "O email já está em uso." }]
  const error = errors.find(error => error[input]);
  error
    ? toast.update(toastId, {
      render: error[input],
      type: 'error',
      isLoading: false,
      autoClose: 5000
    })
    : toast.update(toastId, {
      render: 'Erro ao criar conta',
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
}

//* Verify if the login credentials are ok
function verifyLoginInputs(credentials: AccountEditCredentials): true | String {
  const { email, password, confirmPassword } = credentials;

  const isEmailRight = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const containsNumber = /\d/
  const containsSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/
  const containsUpperCase = /[A-Z]/
  const containsLowerCase = /[a-z]/
  const isLongEnough = /.{8,255}/

  if (!isEmailRight.test(email)) return "Email inválido.";
  if (password === "" && confirmPassword === "") return true;
  if (email === '' || password === '') return "Preencha todos os campos.";
  if (password !== confirmPassword) return "As senhas não coincidem.";
  if (!containsNumber.test(password)) return "A senha deve conter pelo menos um número.";
  if (!containsSpecialCharacter.test(password)) return "A senha deve conter pelo menos um caractere especial.";
  if (!containsUpperCase.test(password)) return "A senha deve conter pelo menos uma letra maiúscula.";
  if (!containsLowerCase.test(password)) return "A senha deve conter pelo menos uma letra minúscula.";
  if (!isLongEnough.test(password)) return "A senha deve conter entre 8 e 255 caracteres.";
  return true;
}