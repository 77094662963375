//* Components
import { StyledInput, StyledPasswordInput } from '../StyledInput/StyledInput';
import { FormStyledButton } from '../StyledButton/StyledButtons';

//* import types
import type { FormEvent, ChangeEvent } from 'react';
import type { LoginCredentials } from '../../types/user';

type Props = {
  errorMsg: string;
  handler: (event: FormEvent<HTMLFormElement>) => void;
  credentials: LoginCredentials;
  setCredentials: (event: any) => void;
}

export function LoginForm({ errorMsg, handler, credentials, setCredentials }: Props) {

  //* Handle any change in the input fields
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  }

  const showErrorMsg = errorMsg !== '' ? { display: 'block' } : { display: 'none' };

  return (
    <form
      className='gap-3 d-flex flex-column justify-content-center align-content-center'
      onSubmit={handler}>

      <StyledInput
        type="email"
        placeholder='exemplo@exe.com'
        id="email"
        name="email"
        msg="Email"
        required={true}
        onChange={handleChange}
      />

      <StyledPasswordInput
        msg="Senha"
        id="password"
        name="password"
        placeholder='**********'
        required={true}
        onChange={handleChange}
      />

      <FormStyledButton type='submit' text='Entrar' />

      <p style={{ ...showErrorMsg, fontFamily: 'Roboto', color: 'red' }}>{errorMsg}</p>
    </form>
  );
}