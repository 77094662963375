//* Libraries imports
import { useEffect, useState, type ReactNode } from "react";
import { BarChart, CartesianGrid, YAxis, XAxis, Tooltip, Bar, Pie, PieChart } from "recharts";

//* Components imports
import { LocalLoading } from "../../../components/Loading/Loading";

//* Types, Request, vars, imports
import "../style.module.sass";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { useParsedChatData, specificHtmlSanitizer, type ParsedChatData, ShowedOptions } from '../utils';

export const ChatChart = () => {
  const { chatData, parsedData, error, loading } = useParsedChatData();
  const { width } = useWindowSize();

  useEffect(() => {
    const chartSubtitle = document.querySelector(".recharts-layer");
    //verify if the chart has all the classes
    if (chartSubtitle?.classList.contains("recharts-layer") &&
      chartSubtitle?.classList.contains("recharts-cartesian-axis") &&
      chartSubtitle?.classList.contains("recharts-xAxis") &&
      chartSubtitle?.classList.contains("xAxis")) {
      //delete it
      chartSubtitle.remove();
    }
  }, [loading]);

  return (
    <div className="d-flex flex-column w-100">
      {loading && <LocalLoading />}
      {error && <h1>{error}</h1>}
      {!loading && !error &&
        <>
          <h3>Total de conversas: {chatData?.total}</h3>
          <div style={{ borderColor: "rgba(0,0,0,0.2)" }} className="border-1 p-1 d-flex flex-column">
            <p>Gráfico de opções que contenham links, e que estes foram clicados.</p>
            <BarChart
              width={width - (width * 0.3)}
              height={300}
              data={parsedData || undefined}
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="uses" fill="#8884d8" onMouseDown={(e) => { console.log(e) }} />
            </BarChart>
          </div>
          <div style={{ gridTemplateColumns: "1fr 1fr" }} className="d-grid w-100 gap-2 mt-5">
            <MostClickedLinks parsedChatData={parsedData} />
            <SeenOptions data={chatData?.showed_options} />
          </div>
        </>
      }
    </div>
  );
}

function InfoCard({ children, title }: { children: ReactNode, title: string }) {
  return (
    <div style={{
      minWidth: '300px',
    }}
      className="d-flex flex-column gap-2 p-1 rounded-1 mt-1"
    >
      <h2>{title}</h2>
      <div
        style={{
          height: '500px',
          overflowY: 'auto',
        }}
        className="d-flex w-100 flex-column"
      >
        {children}
      </div>
    </div>
  )
}

function MostClickedLinks({ parsedChatData }: { parsedChatData?: ParsedChatData[] | null }) {
  const filterMostUsedOptions = (data: ParsedChatData[]) => {
    //sort in descending order
    const sortedData = data.sort((a, b) => b.uses - a.uses);
    //get the first 5
    const filteredData = sortedData.slice(0, 5);
    return filteredData;
  };

  return (
    <InfoCard title="Links mais clicados.">
      <p>Quantas vezes cada link foi clicado, no total.</p>
      {
        parsedChatData
          ? filterMostUsedOptions(parsedChatData).map((data, index) => (
            <div
              key={index}
              style={{ border: '1px solid rgba(0,0,0,0.1)' }}
              className="d-flex w-100 flex-row border-1 p-1 rounded-1 mt-1 gap-2"
            >
              <div className="d-flex me-2">
                <span style={{ fontSize: 24 }}>{index + 1}º</span>
              </div>
              <div className="d-flex flex-column">
                <span>{data.name}</span>
                <span>Clicado <strong style={{ color: "#8884d8" }}>{data.uses}</strong> vezes</span>
              </div>
            </div>
          ))
          : <p>Não há dados para exibir.</p>
      }
    </InfoCard>
  )
}

function SeenOptions({ data }: { data?: ShowedOptions[] }) {
  return (
    <InfoCard title="Opções exibidas">
      <p>Quantas vezes, no total cada opção foi exibida para os usuários.</p>
      {
        //order by count, then render
        data
          ? data?.sort((a, b) => b.count - a.count)
            .map((option, index) => (
              <div
                key={index}
                style={{ border: '1px solid rgba(0,0,0,0.1)' }}
                className="d-flex w-100 flex-row border-1 p-1 rounded-1 mt-1 gap-2"
              >
                <div className="d-flex flex-column justify-content-start align-items-start w-25">
                  <p>
                    Exibido <strong style={{ color: "#8884d8" }}>{option.count}</strong> vezes
                  </p>
                </div>
                <div className="d-flex flex-column w-75">
                  <p>{specificHtmlSanitizer(option.text)}</p>
                </div>
              </div>
            ))
          : <p>Não há dados para exibir.</p>
      }
    </InfoCard>
  )
}