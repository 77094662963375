//* Libraries imports
import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    username: "",
    email: "",
    token: null,
    id: null,
    role: null,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    logout: (state) => {
      state.username = "";
      state.email = "";
      state.token = null;
      state.id = null;
      state.role = null;
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      localStorage.removeItem("id");
      localStorage.removeItem("firstTimeLogin");
      localStorage.removeItem("role");
      window.location.href = "/login"
    },
  },
});

export const { setUsername, setEmail, setToken, setId, setRole,logout } = user.actions;
export default user.reducer;
