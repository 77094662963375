//* Libraries imports
import { Eye } from "phosphor-react";
import { Link } from "react-router-dom";

//* Type imports
import type { Chat } from "../utils";

type Props = {
  chat: Chat;
  controls: boolean;
}

export const ChatCard = ({ chat, controls }: Props) => {
  const day = chat?.updated_at?.slice(0, 10).split('-').reverse().join('/');
  const hour = chat?.updated_at?.slice(11, 16);

  return (
    <>
      <tr>
        <td>{chat.name}</td>
        <td>{chat.ip}</td>
        <td>
          <p>dia: {day}</p>
          <p>Hora: {hour}</p>
        </td>
        {
          controls &&
          <td>
            <Link to={`/dashboard/chats/show/${chat.id}`}>
              <button className="btn btn-success">
                <Eye size={20} />
              </button>
            </Link>
          </td>
        }
      </tr>
    </>
  );
}