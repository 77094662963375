// This components is used to wrap the content of the page in a card

//* Libraries Imports
import type { ReactNode } from "react";

//* Types
type ContainerHolderProps = {
  children: ReactNode;
}

export const ContainerHolder = ({ children }: ContainerHolderProps) => {
  return (
    <div className="card">
      <div className="card-body">
        {children}
      </div>
    </div>
  );
};