//* Function to remove special characters from string
export const cleanUpSpecialChars = (text: string) => {
  return text
    .replace(/[àáâãäå]/g, "a")
    .replace(/[éèê]/g, "e")
    .replace(/[íì]/g, "i")
    .replace(/[óòôõö]/g, "o")
    .replace(/[úùü]/g, "u")
    .replace(/[ç]/g, "c");
};
