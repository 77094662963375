//* Libraries imports
import { useState, useEffect } from "react";

//* Custom imports
import Request from "../../utils/Request";
import type { User } from "./components/UserCard";

type ApiUserList = {
  page: number;
  total: number;
  status: boolean;
  users: User[];
};

export function useUserList(page: number, search_term: string = "") {
  const [total, setTotal] = useState<number>(0);
  const [users, setUsers] = useState<ApiUserList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchData = async () => {
    console.log("fetching data. Page: ", page, " ...");
    setLoading(true);
    try {
      const data = await Request.Get(
        `list?page=${page}&limit=10&search_term=${search_term}`
      );
      if (data && !(data instanceof Response)) {
        setUsers(data);
        //@ts-ignore
        setTotal(data?.total);
        console.log("data", data);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //if page or search_term changes, fetch data again
  useEffect(() => {
    fetchData();
  }, [page, search_term]);

  return { users, loading, error, total };
}
