//* Libraries imports
import { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { LocalLoading } from "../../components/Loading/Loading";
import { Mermaid } from "../../components/Mermaid/Mermaid";

//* Types, Request, vars, imports
import useDebounce from "../../utils/hooks/useDebouncer";
import { useChatShow, optionsParser, type Option } from './utils';

export const ShowChat = () => {
  const [chart, setChart] = useState<string>(`graph TD\n
  Carregando`);
  const debauncedChart = useDebounce(chart, 1000);
  const { id } = useParams<{ id: string }>();
  const { chat, loading } = useChatShow(id || "");

  //* Redirect to dashboard if user is not admin
  const navigate = useNavigate();
  const role = useSelector((state: any) => state.user.role);
  if (role !== 'adm') navigate('/dashboard');


  useEffect(() => {
    setChart(optionsParser(chat?.chat[0]?.options || []));
  }, [chat]);

  return (
    <ContainerHolder>
      <div>
        <h1>{chat?.chat[0]?.name || "Sem nome"}</h1>
        <p>ip: {chat?.chat[0]?.ip || "Carregando..."}</p>
        <p>Este é o mapa da conversa:</p>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "800px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}>
        {
          loading
            ? <LoadingHolder />
            : <TransformWrapper
              centerOnInit
              initialScale={1}
              limitToBounds={false}
              maxScale={100}
            >
              <TransformComponent
                wrapperStyle={{
                  height: "100%",
                  width: "100%",
                  display: loading ? "none" : "block",
                }}
              >
                <Mermaid chart={debauncedChart} />
              </TransformComponent>
            </TransformWrapper>
        }
      </div>
    </ContainerHolder>
  );
}

const LoadingHolder = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "500px",
      height: "500px",
    }}>
      <LocalLoading />
    </div>
  );
}