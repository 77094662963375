//* Libraries imports
import { useEffect, useState } from "react";

//*import type
import type { DayOfWeek } from "../../types/global";
import type {
  Attraction,
  Adrenaline,
  AttractionReview,
  ApiAttraction,
  ApiImage,
} from "../../types/attractions";

//* import request
import Request from "../../utils/Request";

//* Custom hooks

//* Validations
const validateTextSize = (text: string, min: number, max: number) => {
  return text.length >= min && text.length <= max;
};

const validateAttraction = (attraction: Attraction) => {
  const {
    title,
    adrenaline,
    closing_time,
    days_of_week,
    description,
    latitude,
    longitude,
    minimal_age,
    minimal_height,
    opening_time,
    history,
  } = attraction;

  if (!validateTextSize(title, 3, 255))
    return "O título deve ter entre 3 e 255 caracteres.";
  if (!validateTextSize(description, 0, 4000))
    return "A descrição não pode ter mais de 4000 caracteres.";
  if (history !== undefined)
    if (!validateTextSize(history, 0, 4000))
      return "A história não pode ter mais de 4000 caracteres.";
  if (minimal_age < 0 || minimal_age > 100)
    return "A idade mínima deve ser entre 0 e 100 anos.";
  if (minimal_height < 0 || minimal_height > 300)
    return "A altura mínima deve ser entre 0 e 300cm.";
  if (opening_time.length !== 5 || closing_time.length !== 5)
    return "O horário deve ser no formato HH:MM.";
  if (days_of_week.length <= 0) return "Selecione pelo menos um dia da semana.";
  if (
    adrenaline !== "1" &&
    adrenaline !== "2" &&
    adrenaline !== "3" &&
    adrenaline !== "4" &&
    adrenaline !== "5"
  )
    return "Selecione um nível de adrenalina válido.";
  if (typeof latitude !== "number") return "A latitude deve ser um número.";
  if (latitude < -90 || latitude > 90)
    return "A latitude deve ser entre -90 e 90.";
  if (typeof longitude !== "number") return "A longitude deve ser um número.";
  if (longitude < -180 || longitude > 180)
    return "A longitude deve ser entre -180 e 180.";

  return true;
};

//* Validate form data
export const validateFormData = (formData: FormData) => {
  const attraction: Attraction = {
    title: formData.get("title") as string,
    adrenaline: formData.get("adrenaline") as any | Adrenaline,
    closing_time: formData.get("closing_time") as string,
    days_of_week: formData.getAll("days_of_week[]") as DayOfWeek[],
    description: formData.get("description") as string,
    old_images: formData.getAll("old_images[]") as string[],
    images: formData.getAll("images[]") as File[],
    latitude: parseFloat(formData.get("latitude") as string),
    longitude: parseFloat(formData.get("longitude") as string),
    minimal_age: parseInt(formData.get("minimal_age") as string),
    minimal_height: parseInt(formData.get("minimal_height") as string),
    opening_time: formData.get("opening_time") as string,
    history: formData.get("history") as string,
  };

  return validateAttraction(attraction);
};

//* Get attraction from api
export const useAttraction = (attractionId: string) => {
  const [attraction, setAttraction] = useState<ApiAttraction>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const getAttraction = async () => {
    const response = await Request.Get(`attractions/${attractionId}`);
    if (response.error) {
      setError(response.error);
      return setLoading(false);
    }
    setAttraction(response);
    setLoading(false);
  };

  useEffect(() => {
    getAttraction();
  }, []);

  return { attraction, loading, error };
};

//* Get reviews from api
export const useReviews = (attractionId: string) => {
  const [reviews, setReviews] = useState<AttractionReview[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getReviews = async () => {
      const response = await Request.Get(`attractions/${attractionId}/reviews`);
      setReviews(response);
      setLoading(false);
    };
    getReviews();
  }, []);

  return { reviews, loading };
};

//* Delete attraction review
export const deleteReview = async (reviewId: number) => {
  const response = await Request.Delete(
    `attractions/review/destroy/${reviewId}`
  );
};
