//* Libraries imports
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { ServicesForm } from "./components/ServicesForm";

//* Types, utils and styles imports
import Request from "../../utils/Request";
import { validateFormData } from './registerUtils';
import type { FormEvent } from 'react';

export const RegisterServices = () => {
    //* Redirect to dashboard if user is not admin
    const navigate = useNavigate();
    const role = useSelector((state: any) => state.user.role);
    if (role !== 'adm') navigate('/dashboard');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendingToast = toast.loading('Enviando dados...');

    const formData = new FormData(event.target as HTMLFormElement);
    const isOk = validateFormData(formData);
    console.log(formData);
    console.log(isOk);

    if (isOk !== true) {
      toast.dismiss(sendingToast);
      return toast.error(isOk);
    }

    const headers = {
      Accept: "application/json",
      Authorization: "",
    }

    const response: any = await Request.Post('services/create', formData, headers);

    response?.message === "" || response?.message
      ? showErrorMessage(response.message)
      : toast.success('Serviço cadastrado com sucesso!');

    toast.dismiss(sendingToast);
  }

  return (
    <ContainerHolder>
      <ServicesForm handleSubmit={handleSubmit} />
      <ToastContainer />
    </ContainerHolder>
  );
}

type knowErrorsMessage =
  "" |
  "The close hours field is required." |
  "The description field is required." |
  "The images[] field is required." |
  "The open hours field is required." |
  "The title field is required." |
  "The images must be an image." |
  "Service type does not exist" |
  "Service already exists" |
  "The type of service field is required.";

const showErrorMessage = (message: knowErrorsMessage) => {
  if (message === "") return toast.error("Não foi possível estabelecer uma conexão com o servidor!");
  const errorsInPortuguese = {
    "The close hours field is required.": "O horário de fechamento é obrigatório!",
    "The description field is required.": "A descrição é obrigatória!",
    "The images[] field is required.": "A imagem é obrigatória!",
    "The open hours field is required.": "O horário de abertura é obrigatório!",
    "The title field is required.": "O nome do serviço é obrigatório!",
    "The type of service field is required.": "O tipo de serviço é obrigatório!",
    "Service type does not exist": "O tipo de serviço não existe!",
    "The images must be an image.": "A imagem deve ser uma imagem válida!",
    "Service already exists": "O serviço já existe!"
  }

  const error = errorsInPortuguese[message];
  if (error) return toast.error(error);
  return toast.error("Erro desconhecido");
}