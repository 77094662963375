//* Libraries imports
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Star, User, Trash } from "phosphor-react";
import { useParams } from "react-router-dom";

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { ListHeader } from "../../components/ListHeader/ListHeader";
import { LocalLoading } from "../../components/Loading/Loading";

//* Styles, Request, types, etc imports
import './style.sass';
import { useReviews, useAttraction, deleteReview } from './utils';
import type { AttractionReview } from "../../types/attractions";

export const AttractionReviews = () => {
  const { id } = useParams<{ id: string }>();
  const { reviews, loading } = useReviews(id ? id : "");
  const { attraction } = useAttraction(id ? id : "");
  const [filteredReviews, setFilteredReviews] = useState<AttractionReview[]>(reviews || []);

  //* Filter reviews by user name
  const filterReviews = (Event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = Event.target;
    if (value.length > 0) {
      const filtered = reviews?.filter((review) => review.user_name.toLowerCase().includes(value.toLowerCase()));
      setFilteredReviews(filtered || []);
    } else {
      setFilteredReviews(reviews || []);
    }
  };

  //* Set reviews to filtered reviews when reviews are loaded
  useEffect(() => {
    if (reviews) {
      setFilteredReviews(reviews)
    }
  }, [reviews])

  return (
    <ContainerHolder>
      <ListHeader
        title={attraction?.title || "Carregando"}
        description={attraction?.description || "Carregando"}
        filterFunction={filterReviews}
      />
      <div className="review-cards-holder">
        {
          loading
            ? <LocalLoading />
            : filteredReviews?.map((review, index) => (
              <ReviewCard key={index} review={review} />
            ))
        }
      </div>
      <ToastContainer />
    </ContainerHolder>
  );
};


type ReviewCardProps = {
  review: AttractionReview;
}
const ReviewCard = ({ review }: ReviewCardProps) => {

  //* Delete review
  const deleteRev = async () => {
    const msg = toast.loading("Deletando...");
    try {
      const response = await deleteReview(review.id);
      toast.dismiss(msg);
      toast.success("Deletado com sucesso");
    } catch (error) {
      toast.dismiss(msg);
      toast.error("Erro ao deletar");
    }
  }

  return (
    <div className="review-card">
      <div className="review-card-header">
        <div>
          <User size={20} fill="black" />
          <p>{review?.user_name}</p>
        </div>
        <div>
          <Star size={20} fill="black" />
          <p>{review?.rating}</p>
        </div>
      </div>
      <div>
        <p>{review?.comment}</p>
      </div>
      <div>
        <button
          onClick={deleteRev}
          className="btn btn-danger">
          <Trash size={24} />
        </button>
      </div>
    </div>
  );
};