//* Libraries Imports
import { useState, FormEvent } from 'react';

//* Components imports
import { DashboardStyledCheckbox, DashboardStyledInput, DashboardStyledLabel } from "../../../components/StyledInput/StyledInput";
import { ImageSelector } from '../../../components/ImageSelector/ImageSelector';

//* Types imports
import type { ApiAttraction } from "../../../types/attractions";

type CharacterCounter = {
  description: number;
  history: number;
  images: number;
}

// this is a map to convert the days of the week from english to portuguese
const portugueseDaysOfWeek = {
  'monday': 'Segunda-feira',
  'tuesday': 'Terça-feira',
  'wednesday': 'Quarta-feira',
  'thursday': 'Quinta-feira',
  'friday': 'Sexta-feira',
  'saturday': 'Sábado',
  'sunday': 'Domingo',
}

type AttractionsFormProps = {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  startValue?: ApiAttraction;
  oldImages?: string[];
  setOldImages?: (oldImages: string[]) => void;
}

export function AttractionsForm({ handleSubmit, startValue, oldImages, setOldImages }: AttractionsFormProps) {
  const [characterCounter, setCharacterCounter] = useState<CharacterCounter>({
    description: 0,
    history: 0,
    images: 0,
  });

  // check what days are checked from the start value
  const checkedDays = startValue?.days_of_week?.map((day) => portugueseDaysOfWeek[day.weekday]) || [];

  return (
    <form
      className='d-flex flex-column gap-1'
      encType='multipart/form-data'
      method='POST'
      onSubmit={handleSubmit}
    >
      <ImageSelector
        oldImages={oldImages}
        setOldImages={setOldImages}
      />

      <DashboardStyledInput
        id='title'
        placeholder='Tobogã do terror'
        text='Nome da atração: '
        type='text'
        defaultValue={startValue?.title}
      />

      <DashboardStyledLabel htmlFor='description' text='Descrição' />
      <textarea
        className='form-control'
        id='description'
        name="description"
        placeholder='Descrição da atração'
        rows={5}
        onChange={(event) => { setCharacterCounter({ ...characterCounter, description: event.target.value.length }) }}
        defaultValue={startValue?.description}
      />
      <p style={{ color: characterCounter.description > 4000 ? 'red' : 'black' }}>
        {characterCounter.description}/4000
      </p>

      <DashboardStyledLabel htmlFor='history' text='História' />
      <textarea
        className='form-control'
        id='history'
        name="history"
        placeholder='Descrição da atração'
        rows={5}
        onChange={(event) => { setCharacterCounter({ ...characterCounter, history: event.target.value.length }) }}
        defaultValue={startValue?.history}
      />
      <p style={{ color: characterCounter.history > 4000 ? 'red' : 'black' }}>
        {characterCounter.history}/4000
      </p>

      <DashboardStyledInput
        id='minimal_height'
        placeholder='150'
        text='Altura mínima (cm): '
        type='number'
        defaultValue={startValue?.minimal_height}
      />

      <DashboardStyledInput
        id='minimal_age'
        placeholder='12'
        text='Idade mínima: '
        type='number'
        defaultValue={startValue?.minimal_age}
      />

      <DashboardStyledLabel htmlFor='opening_time' text='Horário de abertura' />
      <input
        className='form-control'
        type="time"
        id="opening_time"
        name="opening_time"
        defaultValue={startValue?.days_of_week[0]?.open_time}
      />

      <DashboardStyledLabel htmlFor='closing_time' text='Horário de fechamento' />
      <input
        className='form-control'
        type="time"
        id="closing_time"
        name="closing_time"
        defaultValue={startValue?.days_of_week[0]?.close_time}
      />

      <div className='d-flex flex-column gap-2'>
        <p>Escolha os dias da semana da atração</p>
        <div className='d-flex gap-5 flex-wrap flex-row'>
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Domingo'
            id='sunday'
            value="sunday"
            defaultChecked={checkedDays.includes('Domingo')}
          />
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Segunda'
            id='monday'
            value="monday"
            defaultChecked={checkedDays.includes('Segunda-feira')}
          />
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Terça'
            id='tuesday'
            value="tuesday"
            defaultChecked={checkedDays.includes('Terça-feira')}
          />
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Quarta'
            id='wednesday'
            value="wednesday"
            defaultChecked={checkedDays.includes('Quarta-feira')}
          />
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Quinta'
            id='thursday'
            value="thursday"
            defaultChecked={checkedDays.includes('Quinta-feira')}
          />
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Sexta'
            id='friday'
            value="friday"
            defaultChecked={checkedDays.includes('Sexta-feira')}
          />
          <DashboardStyledCheckbox
            name="days_of_week[]"
            text='Sábado'
            id='saturday'
            value="saturday"
            defaultChecked={checkedDays.includes('Sábado')}
          />
        </div>
      </div>

      <DashboardStyledInput
        id='latitude'
        placeholder='-23.550520'
        text='Latitude: '
        type='number'
        defaultValue={startValue?.latitude}
        step='0.000001'
      />

      <DashboardStyledInput
        id='longitude'
        placeholder='-46.633309'
        text='Longitude: '
        type='number'
        defaultValue={startValue?.longitude}
        step='0.000001'
      />

      <DashboardStyledLabel htmlFor='adrenaline' text='Adrenalina' />
      <select
        className='form-control'
        id='adrenaline'
        name='adrenaline'
        defaultValue={startValue?.adrenaline}
      >
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
      </select>

      <input
        className='btn btn-primary mt-5'
        type="submit"
        value={startValue ? 'Atualizar' : 'Cadastrar'}
      />
    </form>
  );
}