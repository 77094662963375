//* Libraries imports
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//* Components imports
import { AttractionsForm } from './components/AttractionsForm';

//* Util imports
import Request from '../../utils/Request';
import { validateFormData } from './utils';

//* Type imports
import type { FormEvent } from 'react';

export function RegisterAttractions() {
  //* Redirect to dashboard if user is not admin
  const navigate = useNavigate();
  const role = useSelector((state: any) => state.user);
  if (role !== 'adm') navigate('/dashboard');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    console.log(formData);

    const isOk = validateFormData(formData);
    if (isOk !== true) return toast.error(isOk);

    const headers = {
      Accept: "application/json",
      Authorization: "",
    }

    const reqToast = toast.loading('Enviando dados...');
    const response: any = await Request.Post('attractions/create', formData, headers);
    response?.message
      ? toast.update(reqToast, {
        render: 'Erro ao cadastrar atração!',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      })
      : toast.update(reqToast, {
        render: 'Atração cadastrada com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <h2>Adicionar atração</h2>
        <AttractionsForm handleSubmit={handleSubmit} />
        <ToastContainer pauseOnHover={true} />
      </div>
    </div>
  );
}