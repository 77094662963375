//* Libraries imports
import { useState } from 'react';

//* Style import
import './styles.sass'

type FormStyledButtonProps = {
  type: 'submit' | 'reset' | 'button';
  text: string;
}

export const FormStyledButton = ({ type, text }: FormStyledButtonProps) => {
  return (
    <button
      className='styled-button'
      type={type} >
      {text}
    </button >
  )
}

export const DashboardStyledToggableButton = ({ text, onClick }: { text: string, onClick: () => void }) => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <button
      className={'btn ' + (isToggled ? 'btn-success' : 'btn-danger')}
      onClick={() => { onClick(); setIsToggled(!isToggled) }}
    >
      {text}
    </button >
  )
}