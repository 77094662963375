// List all user in the system and allow the user to filter them by name

//* Libraries Imports
import { useState, useEffect, ChangeEvent } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

//* Component import
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { LocalLoading } from '../../components/Loading/Loading';
import { UserCard } from './components/UserCard';
import type { User } from './components/UserCard';
import Pagination from '../../components/Pagination/Pagination';
import { ListHeader } from '../../components/ListHeader/ListHeader';

//* Request, Utils and Types import
import { useUserList } from './utils';
import useDebounce from '../../utils/hooks/useDebouncer';

export function ListUsers() {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const { users, error, total, loading } = useUserList(page, debouncedSearch);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const isAdm = useSelector((state: any) => state.user.role) === 'adm';

  //* Filter the users by name
  const filterUsers = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }

  //* Update the filteredUsers when users change
  useEffect(() => {
    setFilteredUsers(users?.users || []);
  }, [users]);

  useEffect(() => {
    console.log(filteredUsers);
  }, [filteredUsers]);

  return (
    <ContainerHolder>
      <ListHeader
        title="Lista de usuários"
        description="Aqui são listados todas as pessoas do seu sistema."
        filterFunction={filterUsers}
        addLink={isAdm ? "/dashboard/signup" : null}
      />

      <table className='table table-striped table-hover text-center mt-5'>
        <thead className='text-center'>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Cargo</th>
            {
              isAdm && <th scope="col">Ações</th>
            }
          </tr>
        </thead>
        {!loading &&
          <tbody className='w-100'>
            {
              filteredUsers?.map(
                user =>
                  <UserCard
                    key={user.id}
                    user={user}
                    controls={isAdm}
                  />
              )
            }
          </tbody>
        }
      </table>
      <Pagination page={page} setPage={setPage} total={total} loading={loading} />
      {loading && <LocalLoading />}
      {error && toast.error(error)}
      <ToastContainer />
    </ContainerHolder>
  );
}
