//* Libraries imports
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

//* Utils imports
import Request from '../../utils/Request';

//* Components imports
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';

//* Hooks imports
import { useWindowSize } from '../../utils/hooks/useWindowSize';

//* Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar, collapseSidebar } from '../../store/ui';

//* Style imports
import './styles/index.css';
import './styles/App.scss';
import './styles/bootstrap.css';
import { logout } from '../../store/user';

export function Dashboard() {
  const [toggled, setToggled] = useState(useSelector((state: any) => state.ui.toggled));
  const [collapsed, setCollapsed] = useState(useSelector((state: any) => state.ui.collapsed));
  const { width } = useWindowSize();
  const marginLeft = (toggled === false || width <= 768)
    ? 0
    : (collapsed === false ? 270 : 80);

  const dispatch = useDispatch();

  const handleToggled = (value: boolean) => {
    dispatch(toggleSidebar(value));
    setToggled(value);
  }

  const handleCollapsed = (value: boolean) => {
    dispatch(collapseSidebar(value));
    setCollapsed(value);
  }

  useEffect(() => {
    if (width <= 768) {
      handleToggled(false);
      handleCollapsed(false);
    }
    else {
      handleToggled(true);
      handleCollapsed(true);
    }
  }, [width]);

  useEffect(() => {
    thereIsAnyToken(dispatch);
  }, []);

  return (
    <div id="dashboard" className={`app w-100 h-100 position-relative ${toggled ? 'toggled' : ""}`}>
      <Sidebar
        toggled={toggled}
        collapsed={collapsed}
        handleCollapsedChange={handleCollapsed}
        handleToggleSidebar={handleToggled}
      />
      <Header
        toggled={toggled}
        collapsed={collapsed}
        handleCollapsedChange={handleCollapsed}
        handleToggleSidebar={handleToggled}
        windowWidth={width}
      />
      <div className='content-page' style={{ marginTop: "60px" }}>
        <div className='content'
          style={{
            marginLeft: marginLeft,
            transition: 'all 0.3s ease',
            paddingBottom: 100,
            background: '#f8f8fa'
          }}
        >
          <div className='container-fluid'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

/** Verify if there is any token on the local storage */
async function thereIsAnyToken(dispatch:any) {
  const token = localStorage.getItem('token');
  if (token === null || token === "")  dispatch(logout());
  if (token !== null && token !== "") await isTokenValid(token,dispatch);
}

/** Verify if an user token still valid on the API */
async function isTokenValid(token: string,dispatch:any) {
  console.log("fething token");
  const response: any = await Request.Post('auth/validateToken', { token });
  if (response?.message === "Valid token") return;
  else {
    dispatch(logout());
    // window.location.href = '/login';
  }
}