//* Libraries imports
import { useEffect, useRef } from "react";
import mermaid from "mermaid";

type Props = {
  chart: string;
}

export const Mermaid = ({ chart }: Props) => {
  const mermaidRef = useRef<HTMLDivElement>(null);
  // mermaid.initialize({ startOnLoad: true, fontSize: 20 });

  useEffect(() => {
    //remove data-processed="true" from div
    mermaidRef.current?.removeAttribute("data-processed");
    mermaid.init(undefined, mermaidRef.current as HTMLDivElement);
    console.log("update mermaid", chart);
  }, [chart]);

  return (
    <div ref={mermaidRef} id="mermaid" className="mermaid">
      {chart}
    </div>
  );
}