//* Libraries imports
import { useState } from 'react';
import { UserCircle, Lock, EyeClosed, Eye } from 'phosphor-react';

//* Style import
import "./style.sass";

//* Types
import type { ChangeEvent, ReactNode } from 'react';
import type { FormInputChange } from '../../types/global';

type LabelProps = {
  msg: string;
  required?: boolean;
  htmlFor: string;
}

type InputHolderProps = {
  focus: boolean;
  children: ReactNode;
}

interface StyledInputProps {
  id: string;
  type?: string;
  name: string;
  msg: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

type StyledPasswordInputProps = Omit<StyledInputProps, 'type'>

//* Auxiliary components

const Label = ({ msg, required, htmlFor }: LabelProps) => {
  return (
    <label
      htmlFor={htmlFor}>
      {msg}{required && <span> *</span>}
    </label>
  );
}

const InputHolder = ({ children, focus }: InputHolderProps) => {
  return (
    <div
      className={focus ? 'input-holder on-focus' : 'input-holder'}
    >
      {children}
    </div>
  );
}


//* Styled input and label component
export const StyledInput = ({ id, type = "text", name, msg, required, onChange, placeholder }: StyledInputProps) => {
  const [focus, setFocus] = useState(false);
  return (
    <div className='styled-input'>
      <Label htmlFor={id} msg={msg} required={required} />
      <InputHolder focus={focus}>
        {selectIcon(type)}
        <input
          id={id}
          type={type}
          name={name}
          required={required}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </InputHolder>
    </div>
  );
}

export const StyledPasswordInput = ({ id, name, msg, required, onChange, placeholder }: StyledPasswordInputProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <div className='styled-input'>
      <Label msg={msg} required={required} htmlFor={id} />
      <InputHolder focus={focus}>
        <Lock className='position-absolute ms-1' color='black' opacity={0.25} size={32} />
        <input
          id={id}
          name={name}
          required={required}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type={show ? 'text' : 'password'}
        />
        {
          show
            ? <Eye className='position-absolute end-0 me-1 cursor-pointer'
              color='black'
              opacity={0.25}
              size={32}
              onClick={() => setShow(!show)}
            />
            : <EyeClosed className='position-absolute end-0 me-1 cursor-pointer'
              color='black'
              opacity={0.25}
              size={32}
              onClick={() => setShow(!show)}
            />
        }
      </InputHolder>
    </div >
  );
}

//*Dashboard styled input
interface DashboardStyledInputProps {
  placeholder: string;
  onChange?: (event: FormInputChange) => void;
  type: string;
  id: string;
  text: string;
  required?: boolean;
  defaultValue?: string | number;
  step?: string;
}
export const DashboardStyledInput = ({ placeholder, onChange, type, id, text, required = true, defaultValue = "", step }: DashboardStyledInputProps) => {
  return (
    <>
      <DashboardStyledLabel htmlFor={id} text={text} required={required} />
      <input
        className='form-control'
        placeholder={placeholder}
        onChange={(e) => { onChange && onChange(e) }} // If onChange is not undefined, then call it
        type={type}
        name={id}
        id={id}
        required={required}
        defaultValue={defaultValue}
        step={step}
      />
    </>
  )
}

interface DashboardStyledLabelProps {
  text: string;
  htmlFor: string;
  required?: boolean;
}

export const DashboardStyledLabel = ({ text, htmlFor, required = true }: DashboardStyledLabelProps) => {
  return (
    <label className='font-weight-bold col-sm-12 col-form-label' htmlFor={htmlFor}>
      {text}{required && <span style={{ color: "red" }}>*</span>}
    </label>
  )
}
interface DashboardStyledParagraphProps {
  text: string;
}

export const DashboardStyledParagraph = ({ text }: DashboardStyledParagraphProps) => {
  return (
    <p className=''>
      {text}
    </p>
  )
}

//* Select the icon to be displayed on phosphor library
function selectIcon(name: string) {
  switch (name.toLowerCase()) {
    case 'email':
      return <UserCircle className='position-absolute ms-1' color='black' opacity={0.25} size={32} />;
  }
}

interface DashboardStyledCheckboxProps {
  text: string;
  id: string;
  value: string;
  name: string;
  defaultChecked?: boolean;
}

export const DashboardStyledCheckbox = ({ text, id, value, name, defaultChecked = false }: DashboardStyledCheckboxProps) => {
  return (
    <>
      <div className='d-flex flex-row gap-1'>
        <label htmlFor={id} className='form-check-label font-weight-bold'>
          {text}
        </label>
        <input
          type='checkbox'
          defaultChecked={defaultChecked}
          className='form-check-input'
          id={id}
          value={value}
          name={name}
        />
      </div>
    </>
  );
}