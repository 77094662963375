import { API_URL } from "../global";
import axios from "axios";

type Headers = {
  [key: string]: string;
};

type LaravelError = {
  message: string;
  errors: {
    [key: string]: string[];
  };
};

const defaultHeader: Headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "",
};

export default class Request {
  static abortController = new AbortController();

  /** Fetch data from the API using the token */
  static async Post(path: string, body: any, headers: Headers = defaultHeader) {
    const token = localStorage.getItem("token");
    if (token != null) {
      headers["Authorization"] = "Bearer " + token;
    }
    let response = await fetch(API_URL + path, {
      method: "POST",
      body,
      headers,
      signal: this.abortController.signal,
    })
      .then(async (res) => {
        res = await res.json();
        console.log(res);
        return res;
      })
      .then((json) => json)
      .catch((err) => console.error("xo error!!! " + err.message));

    return response;
  }

  static async Get(path: string, headers: Headers = defaultHeader) {
    const token = localStorage.getItem("token");
    if (token != null || "") headers["Authorization"] = "Bearer " + token;

    const response = await fetch(API_URL + path, {
      method: "GET",
      headers,
      signal: this.abortController.signal,
    })
      .then((res) => res.json())
      .then((json) => json)
      .catch((err) => console.error("xo error!!! " + err.message));

    return response;
  }

  static async GetFile(path: string, headers: Headers = {}) {
    let token = localStorage.getItem("token");
    if (token != null) {
      headers["Authorization"] = "Bearer " + token;
    }
    headers = {
      Accept: "application/json",
      ...headers,
    };
    let response = await fetch(API_URL + path, {
      method: "GET",
      headers: headers,
      signal: this.abortController.signal,
    })
      .then((res) => {
        console.log(res.body);
        return res.blob();
      })
      .then((json) => json)
      .catch((err) => {
        console.log(err);
        console.error("xo error!!! " + err.message);
      });

    return response;
  }

  static async Delete(path: string, headers: Headers = {}) {
    let token = localStorage.getItem("token");
    if (token != null) {
      headers["Authorization"] = "Bearer " + token;
    }
    headers = {
      Accept: "application/json",
      ...headers,
    };
    let response = await fetch(API_URL + path, {
      method: "DELETE",
      headers: headers,
    })
      .then((res) => res.json())
      .then((json) => json)
      .catch((err) => console.error("xo error!!! " + err.message));

    return response;
  }

  static async PostProgress(
    path: string,
    body: any,
    headers: Headers = {},
    SetProgress = (e: number) => {}
  ) {
    let token = localStorage.getItem("token");
    if (token != null) {
      headers["Authorization"] = "Bearer " + token;
    }
    headers = {
      Accept: "application/json",
      ...headers,
    };
    let response = await axios
      .post(API_URL + path, body, {
        headers: headers,
        onUploadProgress: (e) => {
          if (e.total) {
            SetProgress((e.loaded / e.total) * 100);
          }
        },
      })
      .then((res) => {
        return res.data;
      })
      .then((json) => json)
      .catch((err) => console.error("xo error!!! " + err.message));

    return response;
  }

  static async GetFileAxios(path: string, headers: Headers = {}) {
    let token = localStorage.getItem("token");
    if (token != null) {
      headers["Authorization"] = "Bearer " + token;
    }
    headers = {
      Accept: "application/json",
      ...headers,
    };
    let response = await axios
      .get(API_URL + path, {
        headers,
        responseType: "document",
      })
      .then((res) => {
        return res.data;
      })
      .then((json) => json)
      .catch((err) => console.error("xo error!!! " + err.message));

    return response;
  }

  //* Send file to server
  static async put(path: string, body: any, headers: Headers = {}) {
    const token = localStorage.getItem("token");
    if (token != null) {
      headers["Authorization"] = "Bearer " + token;
    }
    headers = {
      Accept: "application/json",
      ...headers,
    };
    const response = await axios
      .put(API_URL + path, body, {
        headers,
      })
      .then((res) => {
        return res.data;
      })
      .then((json) => json)
      .catch((err) => console.error("xo error!!! " + err.message));

    return response;
  }
}
