//* Styles imports
import { FaBars } from 'react-icons/fa';

//* Components imports
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';

//* Type imports
import type { ReactNode } from 'react';

type Props = {
  handleToggleSidebar: (toggled: boolean) => void;
  handleCollapsedChange: (checked: boolean) => void;
  collapsed: boolean;
  toggled: boolean;
  windowWidth: number;
}

export const Header = ({ handleCollapsedChange, handleToggleSidebar, collapsed, toggled, windowWidth }: Props) => {
  const marginLeft = collapsed === false ? toggled ? '270px' : '0px' : '80px';

  const handleClick = () => {
    windowWidth <= 768
      ? handleToggleSidebar(!toggled)
      : handleCollapsedChange(!collapsed);
  }

  return (
    <HeaderHolder marginLeft={marginLeft}>
      <Toggler action={handleClick} />
      <NameTypeUser />
      <ProfileMenu />
    </HeaderHolder>
  );
}

const Toggler = ({ action }: { action: () => void }) => {
  return (
    <div
      className="btn-toggle"
      style={{
        background: '#ececf1',
        color: '#353535',
      }}
      onClick={action}
    >
      <FaBars />
    </div>
  );
}

const HeaderHolder = ({ children, marginLeft }: { children: ReactNode, marginLeft: string }) => {
  return (
    <div className='topbar'>
      <nav
        className="navbar-custom d-flex justify-content-between align-items-center"
        style={{ marginLeft, transition: 'all 0.3s ' }}
      >
        {children}
      </nav>
    </div>
  );
};

const NameTypeUser = () => {
  const username = localStorage.getItem('username'); // get username from localstorage
  const role = localStorage.getItem('role'); // get role from localstorage
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
      <span style={{ fontWeight: "bold" }}>Nome do usuário: </span>{` ${username}`}
      <span style={{ fontWeight: "bold" }}>Tipo de usuário: </span>{` ${role}`}
    </div>
  );
}