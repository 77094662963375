//* Libraries imports
import { useState, useEffect } from "react";
import { ToastContainer, toast, Id } from "react-toastify";

type PaginationProps = {
  page: number,
  setPage: (page: number) => void,
  total: number,
  loading: boolean
}

export default function Pagination({ page, setPage, total, loading }: PaginationProps) {
  const [previous, setPrevious] = useState<number>(1);
  const [canGoNext, setCanGoNext] = useState<boolean>(false);

  const actualPageButtonActive = () => {
    const isPage1 = page === 1;
    const isPage2 = page === 2;
    const isThereMoreThan1Page = total > 1;
    const res = isPage1 && isThereMoreThan1Page || isPage2;
    return !res;
  }

  //return true to deactivate button
  const nextPageButtonActive = () => {
    const lessThanTotal = page < total;
    const actualPageIsEqualTotal = page === total;
    const canGoToNextPage = canGoNext;
    if (total <= 2) return true;
    if (total === 3 && page === 2) return false;
    if (total === 3 && page === 3) return true;
    if (lessThanTotal && canGoToNextPage) return false;
    if (actualPageIsEqualTotal) return true;
    return false;
  }

  useEffect(() => {
    setPrevious(page - 1);
    setCanGoNext(page < total);
  }, [page, total]);

  useEffect(() => {
    //if is loading, show loading toast
    if (loading) {
      toast.loading("Carregando...", {
        autoClose: false,
        toastId: "loading"
      });
    } else {
      //if is not loading, close loading toast
      toast.dismiss("loading");
    }
  }, [loading]);

  return (
    // start pagination
    <div className="d-flex flex-row w-100 justify-content-end">
      {
        total > 1 && loading === false &&
        <>
          <Button
            text="Inicio"
            onClick={() => { //verify if page is bigger than 1, if true, set page to 1, else, do nothing
              if (!loading && page > 1) setPage(1);
              else toast.error("Aguarde o carregamento da página");
            }}
            disabled={previous <= 0}
          />
          {/* previous page button */}
          <Button
            text={page - 1 <= 0 ? ("1") : (page - 1 + "")}
            onClick={() => { //verify if page is 1, if true, set page to 1, else, set page to page - 1
              if (!loading) setPage(page - 1 <= 0 ? 1 : page - 1);
              else toast.error("Aguarde o carregamento da página");
            }}
            disabled={previous <= 0}
          />
          {/* actual page button or page 2 button */}
          <Button
            text={page === 1 ? "2" : (page + "")}
            onClick={() => { //verify if is page 1, if true, set page to 2, else, set page to page
              if (!loading) setPage(page === 1 ? 2 : page);
              else toast.error("Aguarde o carregamento da página");
            }}
            // if is page 1 and total is 1, set true.
            // if is page 1 and total is bigger than 1, set false
            disabled={actualPageButtonActive()}
          />
          {/* next page button */}
          <Button
            text={page === total ? "..." : page + 1 === 2 ? "3" : (page + 1 + "")}
            onClick={() => {//verify if page is 1, if true, set page to 3, else, set page to page + 1
              if (!loading) setPage(page + 1 === 2 ? 3 : page + 1 >= total ? total : page + 1);
              else toast.error("Aguarde o carregamento da página");
            }}
            disabled={nextPageButtonActive()}
          />
          {/* last page button */}
          <Button
            text="Fim"
            onClick={() => {// verify if is last page, if true, do nothing, else, set page to last page
              if (!loading) setPage(total);
              else toast.error("Aguarde o carregamento da página");
            }}
            disabled={page >= total}
          />
        </>
      }

      <ToastContainer />
    </div>
  )
}

type ButtonProps = {
  text: string,
  onClick: () => void,
  disabled?: boolean
}
const Button = ({ text, onClick, disabled }: ButtonProps) => {
  return (
    <button
      className={(disabled ? "btn-outline-primary" : "btn-primary") + " btn ms-2"}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}