//* Libraries imports

//* Components imports
import { ContainerHolder } from "../../components/ContainerHolder/ContainerHolder";
import { ChatChart } from "./components/ChatChart";

//* Types, Request, vars, imports

export const ChatOverview = () => {

  return (
    <ContainerHolder>
      <h1>Visão geral</h1>
      <ChatChart />
    </ContainerHolder>
  );
}